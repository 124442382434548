import React, { useReducer } from 'react';
import { Controller, controllerReducer, controllerStateInitialValue } from './controller';
import { CountsInTime } from './countsInTime';
import { CountsTotal } from './countsTotal';
import './lineagesPage.css';

export function LineagesPage(): JSX.Element {
  const [state, dispatch] = useReducer(controllerReducer, controllerStateInitialValue());

  return (
    <>
      <Controller state={state} dispatch={dispatch} />
      <CountsInTime state={state} dispatch={dispatch} />
      <CountsTotal state={state} dispatch={dispatch} />
    </>
  );
}

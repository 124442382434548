import { gql } from '@apollo/client';
import { GraphqlSchema } from '@covd/lib';

export interface GraphqlData {
  sample?: Pick<
    GraphqlSchema.Sample,
    | 'id'
    | 'labSampleId'
    | 'sequenceId'
    | 'accessionId'
    | 'lineage'
    | 'laboratory'
    | 'gender'
    | 'age'
    | 'collectionDate'
    | 'country'
    | 'sequence'
    | 'locationLabel'
  >;
}

export const graphqlQuery = gql`
  query GetSample($id: String!) {
    sample(id: $id) {
      id
      labSampleId
      sequenceId
      accessionId
      lineage
      locationLabel
      laboratory
      gender
      age
      collectionDate
      country
      sequence
    }
  }
`;

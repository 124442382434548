/**
 * FIXME: whole file
 */

import { gql, useQuery } from '@apollo/client';
import {
  Button,
  Classes,
  ControlGroup,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Menu,
  MenuItem,
  NumericInput,
  Radio,
  RadioGroup,
} from '@blueprintjs/core';
import { DateRangeInput } from '@blueprintjs/datetime';
import { Popover2 } from '@blueprintjs/popover2';
import { GraphqlSchema } from '@covd/lib';
import * as _ from 'lodash';
import 'moment/locale/cs';
import React, { useReducer } from 'react';
import MomentLocaleUtils from 'react-day-picker/moment';
import { Link } from 'react-router-dom';
import { Box } from '../../components/box';
import {
  FormParams,
  numericInputChange,
  setAutoFocus,
  textInputChange,
} from '../../components/forms/formState';
import { Map } from './map';

type Filter = 'location' | 'gender' | 'age' | 'lineage' | 'collectionDate';

interface State {
  filters: Filter[];
  mapEnabled: boolean;
}

type Action =
  | { action: 'addFilter'; name: Filter }
  | { action: 'removeFilter'; name: Filter }
  | { action: 'toggleMap' };

function reducer(state: State, action: Action): State {
  switch (action.action) {
    case 'addFilter':
      return { ...state, filters: [...state.filters, action.name] };

    case 'removeFilter':
      return { ...state, filters: state.filters.filter((filter) => filter !== action.name) };

    case 'toggleMap':
      return { ...state, mapEnabled: !state.mapEnabled };

    default:
      return state;
  }
}

export function Form(props: FormParams<GraphqlSchema.SamplesFilter>): JSX.Element {
  const [state, dispatch] = useReducer(reducer, {
    // filters: ['location', 'gender', 'age', 'lineage', 'collectionDate'],
    filters: [],
    mapEnabled: true,
    // mapEnabled: false,
  });

  return (
    <>
      <Box key={props.state.key}>
        <div className={Classes.FOCUS_DISABLED} style={{ display: 'flex' }}>
          <div style={{ marginLeft: 'auto' }}>
            <Popover2
              content={
                <Menu>
                  {!state.filters.includes('location') && (
                    <MenuItem
                      text="Lokace"
                      icon="map"
                      onClick={() => dispatch({ action: 'addFilter', name: 'location' })}
                    />
                  )}

                  {!state.filters.includes('gender') && (
                    <MenuItem
                      text="Pohlaví"
                      icon="user"
                      onClick={() => dispatch({ action: 'addFilter', name: 'gender' })}
                    />
                  )}

                  {!state.filters.includes('age') && (
                    <MenuItem
                      text="Věk"
                      icon="user"
                      onClick={() => dispatch({ action: 'addFilter', name: 'age' })}
                    />
                  )}

                  {!state.filters.includes('lineage') && (
                    <MenuItem
                      text="Linie"
                      icon="flows"
                      onClick={() => dispatch({ action: 'addFilter', name: 'lineage' })}
                    />
                  )}

                  {!state.filters.includes('collectionDate') && (
                    <MenuItem
                      text="Datum odběru"
                      icon="calendar"
                      onClick={() => dispatch({ action: 'addFilter', name: 'collectionDate' })}
                    />
                  )}
                </Menu>
              }
              minimal
              placement="bottom-start"
              renderTarget={({ isOpen, ref, ...p }) => (
                <Button
                  {...p}
                  active={isOpen}
                  elementRef={ref || undefined}
                  text="Přidat filtr"
                  icon="filter-list"
                  minimal
                />
              )}
            />

            <Button icon="path-search" onClick={() => dispatch({ action: 'toggleMap' })} minimal>
              {state.mapEnabled ? 'Skrýt mapu' : 'Zobrazit mapu'}
            </Button>

            <Button icon="reset" minimal onClick={() => props.dispatch({ action: 'reset' })}>
              Reset formuláře
            </Button>

            <Link
              to="/samples-map"
              className={`${Classes.BUTTON} ${Classes.MINIMAL}`}
              role="button"
            >
              Mapa po obdobích
            </Link>
          </div>
        </div>

        {state.filters.includes('location') && <LocationFilter {...props} />}
        {state.filters.includes('collectionDate') && <CollectionDateFilter {...props} />}
        {state.filters.includes('age') && <AgeFilter {...props} />}
        {state.filters.includes('lineage') && <LineageFilter {...props} />}
        {state.filters.includes('gender') && <GenderFilter {...props} />}
      </Box>

      {state.mapEnabled && <Map {...props} />}
    </>
  );
}

function LocationFilter(props: FormParams<GraphqlSchema.SamplesFilter>): JSX.Element {
  return (
    <FormGroup
      labelInfo="(vložte alespoň jeden znak)"
      label="Lokace"
      labelFor="location-input"
      // inline
    >
      <InputGroup
        id="location-input"
        leftIcon="map"
        placeholder="Místo výskytu ..."
        name="location"
        onChange={textInputChange(props.dispatch, 500)}
        defaultValue={props.state.location}
        autoFocus={setAutoFocus(props.state, 'location')}
        // size={50}
      />
    </FormGroup>
  );
}

function GenderFilter(props: FormParams<GraphqlSchema.SamplesFilter>): JSX.Element {
  return (
    <RadioGroup
      label="Pohlaví"
      name="gender"
      onChange={textInputChange(props.dispatch)}
      selectedValue={props.state.gender || ''}
      inline
    >
      <Radio label="Nezáleží" value="" />
      <Radio label="Muž" value="male" />
      <Radio label="Žena" value="female" />
    </RadioGroup>
  );
}

function AgeFilter(props: FormParams<GraphqlSchema.SamplesFilter>): JSX.Element {
  return (
    <FormGroup label="Věk" labelInfo="(vložte jedno nebo oboje)">
      <ControlGroup>
        <NumericInput
          placeholder=">="
          name="ageGte"
          onValueChange={numericInputChange(props.dispatch, 500)}
          buttonPosition="none"
          // size={1}
          defaultValue={props.state.ageGte}
          style={{ textAlign: 'center' }}
          autoFocus={setAutoFocus(props.state, 'ageGte')}
        />

        <NumericInput
          placeholder="<="
          name="ageLte"
          onValueChange={numericInputChange(props.dispatch, 500)}
          buttonPosition="none"
          // size={1}
          defaultValue={props.state.ageLte}
          style={{ textAlign: 'center' }}
          autoFocus={setAutoFocus(props.state, 'ageLte')}
        />
      </ControlGroup>
    </FormGroup>
  );
}

function LineageFilter(props: FormParams<GraphqlSchema.SamplesFilter>): JSX.Element | null {
  const { data } = useQuery<{ sampleLineages: GraphqlSchema.SampleLineage[] }>(gql`
    query {
      sampleLineages {
        name
        count
      }
    }
  `);

  if (!data?.sampleLineages?.length) {
    return null;
  }

  console.log();

  const lineages = _.reverse(_.sortBy(data.sampleLineages, 'count'));

  return (
    <FormGroup label="Linie" labelInfo="">
      <HTMLSelect
        options={[
          '',
          ...lineages.map((lineage) => {
            return { label: `${lineage.name} (${lineage.count}x)`, value: lineage.name };
          }),
        ]}
        // id="location-input"
        // leftIcon="map"
        // iconProps={{ icon: 'locate' }}
        placeholder="Místo výskytu ..."
        name="lineage"
        onChange={textInputChange(props.dispatch)}
        defaultValue={props.state.lineage}
        autoFocus={setAutoFocus(props.state, 'lineage')}
      />
    </FormGroup>
  );
}

function CollectionDateFilter(props: FormParams<GraphqlSchema.SamplesFilter>): JSX.Element {
  return (
    <FormGroup label="Datum odběru" labelInfo="(vložte jedno datum nebo oboje)">
      <DateRangeInput
        formatDate={(date) => date.toLocaleDateString('cs')}
        locale="cs"
        localeUtils={MomentLocaleUtils}
        onChange={(range) => {
          props.dispatch({
            action: 'setValues',
            values: {
              collectionDateGte: range[0],
              collectionDateLte: range[1],
            },
          });
        }}
        parseDate={(str) => new Date(str)}
        defaultValue={[
          props.state.collectionDateGte || null,
          props.state.collectionDateLte || null,
        ]}
        closeOnSelection
        shortcuts={false}
        allowSingleDayRange
      />
    </FormGroup>
  );
}

import { AnchorButton, H3 } from '@blueprintjs/core';
import { GraphqlSchema } from '@covd/lib';
import React from 'react';
import { Box } from '../../components/box';
import { humanSize } from '../../utils/humanSize';
import { prefixServerUrl } from '../../utils/prefixServerUrl';

interface ReportFilesProps {
  reportFiles: GraphqlSchema.ReportFile[];
}

export function ReportFiles(props: ReportFilesProps): JSX.Element | null {
  if (!props.reportFiles.length) {
    return null;
  }

  return (
    <Box>
      <H3>Reporty</H3>

      {props.reportFiles.slice(-3).map((reportFile) => {
        const match = reportFile.name.match(/report-cogcz(-rok-(\d+))?-tyden-(\d+)/);

        if (!match) {
          return null;
        }

        const year = match[2] ? parseInt(match[2]) : 21;
        const week = parseInt(match[3]);
        const readableName = week ? `COG-CZ Report ${week}/${year}.pdf` : reportFile.name;

        return (
          <>
            <AnchorButton
              key={reportFile.name}
              className="margin-left-10 margin-bottom-10"
              icon="download"
              href={prefixServerUrl(`/f/${reportFile.name}`)}
              target="_blank"
              rel="noreferrer"
              outlined
            >
              {readableName} ({humanSize(reportFile.size, 0)})
            </AnchorButton>
          </>
        );
      })}
    </Box>
  );
}

import { Classes } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { PropsWithChildren } from 'react';
import { Chart } from 'chart.js';
import { getCSS } from '../utils/css';

type PossibleThemes = 'light' | 'dark';

interface ThemeContextType {
  theme: PossibleThemes;
  toggleTheme: () => void;
}

export const ThemeContext = React.createContext<ThemeContextType>({
  theme: 'light',
  toggleTheme: () => {
    return;
  },
});

function setRootDataset(theme: PossibleThemes) {
  const dataset = document?.querySelector('html')?.dataset;

  if (dataset) {
    dataset.theme = theme;
  }
}

export function blueprintThemeClass(theme: string): string {
  return theme === 'light' ? '' : Classes.DARK;
}

export function Themed(props: PropsWithChildren<any>): JSX.Element {
  let defaultTheme = localStorage.getItem('theme');

  if (defaultTheme !== 'dark') {
    defaultTheme = 'light';
  }

  const [theme, setTheme] = useState(defaultTheme as PossibleThemes);

  setRootDataset(theme);

  useEffect(() => {
    // Chart.defaults.defaultFontColor = getCSS('--text-color');
    Chart.defaults.color = getCSS('--text-color');

    localStorage.setItem('theme', theme);
    setRootDataset(theme);
  }, [theme]);

  const toggleTheme = () => {
    Chart.defaults.color = getCSS('--text-color');
    // Chart.defaults.global.defaultFontColor = getCSS('--text-color');
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div className={blueprintThemeClass(theme)}>{props.children}</div>
    </ThemeContext.Provider>
  );
}

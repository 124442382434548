import { H3 } from '@blueprintjs/core';
import React from 'react';
import { Box } from '../../components/box';
import { Email } from '../../components/email';

interface PersonProps {
  name: string;
  email?: string;
  position: string;
  institution: string;
}

export function People(): JSX.Element {
  return (
    <Box className="about-people">
      <H3>Lidé a kontakty</H3>

      <ul>
        <li>
          Kontaktní email pro dotazy <Email email="herv@img.cas.cz" />
        </li>

        <li>
          <Person
            name="Ondřej Moravčík"
            email="ondrej.moravcik@img.cas.cz"
            position="Technik"
            institution="Ústav molekulární genetiky AV ČR"
          />
        </li>

        <li>
          <Person
            name="Jan Pačes"
            email="jan.paces@img.cas.cz"
            position="Superviser"
            institution="Ústav molekulární genetiky AV ČR"
          />
        </li>

        <li>
          <Person
            name="Michal Kolář"
            email="kolarmi@img.cas.cz"
            position="Superviser"
            institution="Ústav molekulární genetiky AV ČR"
          />
        </li>

        <li>
          Helena Jiřincová - Virolog
          <p>
            Vedoucího Národní referenční laboratoře pro chřipku a nechřipková respirační virová
            onemocnění
          </p>
        </li>

        <li>
          <Person
            name="Petr Brož"
            email="info@bioxsys.cz"
            position="Bioinformatik"
            institution="Bioxsys"
          />
        </li>

        <li>
          <Person
            name="Karel Berka"
            email="karel.berka@upol.cz"
            position="Bioinformatik"
            institution="Univerzita Palackého v Olomouci"
          />
        </li>

        <li>
          <Person
            name="Vladimír Hampl"
            email="vlada@natur.cuni.cz"
            position="Superviser"
            institution="Přírodovědecká fakulta UK - BIOCEV"
          />
        </li>
      </ul>
    </Box>
  );
}

function Person(props: PersonProps) {
  return (
    <div>
      {props.name} - {props.institution} - {props.position}
      {props.email && (
        <div>
          <Email email={props.email} />
        </div>
      )}
    </div>
  );
}

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../routes';

export function Content(): JSX.Element {
  return (
    <div className="main-wrapper">
      <div className="main">
        <Switch>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              render={({ location }) => <route.component key={location.key} />}
            />
          ))}
        </Switch>
      </div>
    </div>
  );
}

import { useQuery } from '@apollo/client';
import { Button, H3 } from '@blueprintjs/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '../../components/box';
import { Error } from '../../components/error';
import { BorderedLineage } from '../../components/lineages';
import { Loading } from '../../components/loading';
import { NoDataToDisplay } from '../../components/noDataToDisplay';
import { TableDetails, TableDetailsData } from '../../components/tableDetails';
import { Title } from '../../components/title';
import { translateGender } from '../../utils/translate';
import { GraphqlData, graphqlQuery } from './graphq';

interface PageParams {
  id: string;
}

export function SamplePage(): JSX.Element {
  const { id } = useParams<PageParams>();

  const { loading, error, data } = useQuery<GraphqlData, { id: string }>(graphqlQuery, {
    variables: { id },
  });

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;

  if (!data?.sample) {
    return <NoDataToDisplay />;
  }

  const { sample } = data;

  const tableData: TableDetailsData = [
    { label: 'Id', value: sample.id },
    { label: 'Accession Id', value: sample.accessionId || '-' },
    { label: 'Linie', value: <BorderedLineage lineage={sample.lineage} /> },
    { label: 'Lokace', value: sample.locationLabel || '-' },
    { label: 'Laboratoř', value: sample.laboratory || '-' },
    { label: 'Čas odběru', value: sample.collectionDate || '-' },
    { label: 'Pohlaví', value: translateGender(sample.gender) || '-' },
    { label: 'Věk', value: sample.age || '-' },
  ];

  return (
    <>
      <Title>Vzorek: {sample.id}</Title>

      <Box>
        <TableDetails data={tableData} />
      </Box>

      <Sequence sample={sample} />
    </>
  );
}

function Sequence({ sample }: { sample: GraphqlData['sample'] }) {
  if (!sample || !sample?.sequence) {
    return null;
  }

  const download = () => {
    const sequence = `>${sample.id}\n${sample.sequence}`;
    const url = URL.createObjectURL(new Blob([sequence], { type: 'text/x-fasta' }));

    const a = document.createElement('a');
    a.href = url;
    a.innerHTML = '';
    document.body.appendChild(a);
    a.click();
  };

  return (
    <Box>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <H3>Sekvence</H3>

        <Button icon="download" onClick={download} minimal>
          FASTA
        </Button>
      </div>
    </Box>
  );
}

import { Icon } from '@blueprintjs/core';
import React from 'react';
import './email.css';

interface EmailProps {
  name?: string;
  email: string;
}

export function Email(props: EmailProps): JSX.Element {
  const [before, after] = props.email.split('@', 2);

  if (props.name) {
    return (
      <div className="email">
        {props.name}
        <span className="email__address">
          (<Icon className="email__envelope" icon="envelope" />
          {before}
          <span className="email__at">&nbsp;</span>
          {after})
        </span>
      </div>
    );
  } else {
    return (
      <div className="email email--no-name">
        <span className="email__address">
          <Icon className="email__envelope" icon="envelope" />
          {before}
          <span className="email__at">&nbsp;</span>
          {after}
        </span>
      </div>
    );
  }
}

import React from 'react';
import { getLineageColor } from '../utils/lineageColor';
import './lineages.css';

interface BorderedLineageProps {
  lineage?: string;
}

export function BorderedLineage(props: BorderedLineageProps): JSX.Element {
  if (!props.lineage) {
    return <>{props.lineage}</>;
  }

  return (
    <span
      style={{
        borderBottom: `3px solid ${getLineageColor(props.lineage)}`,
      }}
    >
      {props.lineage}
    </span>
  );
}

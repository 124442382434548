import './level.css';
import React from 'react';

interface LevelProps {
  className?: string;
  boxed?: boolean;
  large?: boolean;
  items: Array<{
    label: string;
    value: string | number | JSX.Element;
  }>;
}

export function Level(props: LevelProps) {
  let className = 'level';

  if (props.className) {
    className += ` ${props.className}`;
  }

  if (props.boxed) {
    className += ' level--boxed';
  }

  if (props.large) {
    className += ' level--large';
  }

  return (
    <div className={className}>
      {props.items.map((item) => (
        <div key={item.label} className="level__item">
          <p className="level__heading">{item.label}</p>
          <p className="level__content">
            {typeof item.value === 'number' ? item.value.toLocaleString() : item.value}
          </p>
        </div>
      ))}
    </div>
  );
}

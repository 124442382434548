import { AnchorButton } from '@blueprintjs/core';
import React from 'react';
import { Box } from '../../components/box';
import { prefixServerUrl } from '../../utils/prefixServerUrl';

export function Download(): JSX.Element {
  return (
    <Box>
      Ke stažení (všechny vzorky)
      <AnchorButton
        className="margin-left-10"
        icon="download"
        href={prefixServerUrl('/f/master.tsv')}
        target="_blank"
        rel="noreferrer"
        minimal
      >
        Jako TSV
      </AnchorButton>
      <AnchorButton
        className="margin-left-10"
        icon="download"
        href={prefixServerUrl('/f/master.json')}
        target="_blank"
        rel="noreferrer"
        minimal
      >
        Jako JSON
      </AnchorButton>
      <AnchorButton
        className="margin-left-10"
        icon="download"
        href={prefixServerUrl('/f/master.fa.gz')}
        target="_blank"
        rel="noreferrer"
        minimal
      >
        FASTA sekvence
      </AnchorButton>
      {/* <Drawer title="fwefwef">fwefwefwef</Drawer> */}
    </Box>
  );
}

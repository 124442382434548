import React, { PropsWithChildren } from 'react';
import './box.css';

interface BoxProps extends Pick<JSX.IntrinsicElements['div'], 'ref'> {
  className?: string;
  style?: React.CSSProperties;
}

export function Box(props: PropsWithChildren<BoxProps>): JSX.Element {
  return (
    // React.forwardRef
    <div ref={props.ref} className={`box ${props.className || ''}`} style={props.style}>
      {props.children}
    </div>
  );
}

import { H3 } from '@blueprintjs/core';
import { GraphqlSchema } from '@covd/lib';
import * as _ from 'lodash';
import React from 'react';
import { Box } from '../../components/box';
import { RenderChart } from '../../components/renderChart';

interface LaboratoriesProps {
  laboratories?: GraphqlSchema.Laboratory[];
}

export function Laboratories(props: LaboratoriesProps): JSX.Element | null {
  if (!props.laboratories) {
    return null;
  }

  const laboratories = _.sortBy(
    props.laboratories.filter((laboratory) => laboratory.numberOfSamples > 0),
    (laboratory) => -laboratory.numberOfSamples,
  );

  return (
    <Box>
      <H3>Počet vzorku podle laboratoří</H3>

      <RenderChart
        options={{
          type: 'bar',
          data: {
            datasets: [
              {
                label: 'Počet vzorků',
                data: laboratories.map((laboratory) => laboratory.numberOfSamples),
                backgroundColor: '#fca311',
              },
            ],
            labels: laboratories.map((laboratory) => laboratory.name),
          },
          options: {
            indexAxis: 'y',
            plugins: {
              tooltip: {
                enabled: true,
              },
              legend: {
                display: false,
              },
            },
          },
        }}
      />
    </Box>
  );
}

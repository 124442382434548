export function translateGender(gender?: string): string | null {
  switch (gender) {
    case 'male':
      return 'Muž';
    case 'female':
      return 'Žena';
    default:
      return null;
  }
}

import { gql, useQuery } from '@apollo/client';
import { H3 } from '@blueprintjs/core';
import { czechiaGeo, CzechiaGeoProperties, GraphqlSchema } from '@covd/lib';
import * as _ from 'lodash';
import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { ThemeContext } from '../../app/theme';
import { Error } from '../../components/error';
import { FormParams } from '../../components/forms/formState';
import { Loading } from '../../components/loading';
import { NoDataToDisplay } from '../../components/noDataToDisplay';
import { colorGradient, logColorGradient } from '../../utils/colorGradients';
import { getCSS } from '../../utils/css';
import czMap from './cz.json';

interface Item {
  region?: string;
  count?: number;
}

export interface GraphqlData {
  samplesOnMap: {
    items: Item[];
  };
}

export const graphqlQuery = gql`
  query GetSamples(
    $accessionId: String
    $lineage: String
    $location: String
    $laboratory: String
    $gender: String
    $ageLte: Int
    $ageGte: Int
    $collectionDateLte: Date
    $collectionDateGte: Date
  ) {
    samplesOnMap(
      filter: {
        accessionId: $accessionId
        lineage: $lineage
        location: $location
        laboratory: $laboratory
        gender: $gender
        ageLte: $ageLte
        ageGte: $ageGte
        collectionDateLte: $collectionDateLte
        collectionDateGte: $collectionDateGte
      }
    ) {
      items {
        region
        count
      }
    }
  }
`;

type Scale = 'linear' | 'log';

const minHeatColor = '#f5f3f0';
const heatColor = '#fca311';
const heatHoverColor = '#bfd7ea';
const legendHeight = 20;
const legendWidth = 200;

export function Map(
  props: Pick<FormParams<GraphqlSchema.SamplesFilter>, 'state'> & {
    hideTitle?: boolean;
    scale?: Scale;
  },
): JSX.Element {
  const { loading, error, data } = useQuery<GraphqlData, GraphqlSchema.SamplesFilter>(
    graphqlQuery,
    {
      variables: {
        accessionId: props.state.accessionId,
        lineage: props.state.lineage,
        location: props.state.location,
        laboratory: props.state.laboratory,
        gender: props.state.gender,
        ageLte: props.state.ageLte,
        ageGte: props.state.ageGte,
        collectionDateLte: props.state.collectionDateLte,
        collectionDateGte: props.state.collectionDateGte,
      },
    },
  );

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;

  if (!data?.samplesOnMap?.items?.length) {
    return <NoDataToDisplay />;
  }

  const items = data.samplesOnMap.items;
  const maxCount = _.maxBy(items, 'count')?.count || 1;

  return (
    <MapContent items={items} maxCount={maxCount} hideTitle={props.hideTitle} scale={props.scale} />
  );
}

function MapContent(props: {
  items: Item[];
  maxCount: number;
  hideTitle?: boolean;
  scale?: Scale;
}) {
  const [tooltip, setTooltip] = useState<Item | null>();
  const { items, maxCount } = props;

  return (
    <ThemeContext.Consumer>
      {() => (
        <>
          <div style={{ float: 'right' }}>
            <div
              style={{
                background: `linear-gradient(90deg, ${getCSS(
                  '--box-background-color',
                )} 0%, ${heatColor} 100%)`,
                height: legendHeight,
                width: legendWidth,
                position: 'relative',
              }}
            >
              {tooltip?.count ? (
                <div
                  style={{
                    display: 'inline-block',
                    width: 10,
                    height: legendHeight,
                    background: heatHoverColor,
                    position: 'absolute',
                    left: legendWidth * (tooltip.count / maxCount),
                  }}
                ></div>
              ) : null}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>0</span>
              <span>{maxCount}</span>
            </div>
          </div>

          {!props.hideTitle && <H3>Počet sekvencí pro jednotlivé okresy</H3>}

          <div style={{ height: 20 }}>
            {tooltip && (
              <>
                Kraj: {tooltip.region}
                <br />
                Počet: {tooltip.count}
              </>
            )}
          </div>

          <ComposableMap
            projection="geoMercator"
            projectionConfig={{
              scale: 4000,
              center: [15.5, 49.8],
            }}
            height={300}
          >
            <Geographies geography={czMap}>
              {({ geographies }) =>
                geographies.map((geo: { rsmKey: string; properties: { region: string } }) => {
                  const item = items.find((item) => item.region === geo.properties.region);
                  const count = item?.count || 0;

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={() => {
                        setTooltip(
                          item || {
                            region: geo.properties.region,
                            // district: geo.properties.NAZ_LAU1,
                            count: 0,
                          },
                        );
                      }}
                      onMouseLeave={() => {
                        setTooltip(null);
                      }}
                      style={{
                        default: {
                          fill:
                            props.scale === 'linear'
                              ? colorGradient(minHeatColor, heatColor, count / maxCount)
                              : logColorGradient(minHeatColor, heatColor, count, maxCount),
                          stroke: '#707078',
                        },
                        hover: {
                          fill: heatHoverColor,
                          stroke: '#707078',
                        },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ComposableMap>
        </>
      )}
    </ThemeContext.Consumer>
  );
}

import { gql, useQuery } from '@apollo/client';
import { AnchorButton, H2, H3 } from '@blueprintjs/core';
import { GraphqlSchema } from '@covd/lib';
import React from 'react';
import { Box } from '../../components/box';
import { Error } from '../../components/error';
import { Loading } from '../../components/loading';
import { NoDataToDisplay } from '../../components/noDataToDisplay';
import { humanSize } from '../../utils/humanSize';
import { prefixServerUrl } from '../../utils/prefixServerUrl';
import { reverse } from 'lodash';

export interface GraphqlData {
  report: {
    reportFiles: GraphqlSchema.ReportFile[];
  };
}

export const graphqlQuery = gql`
  query {
    report {
      reportFiles {
        size
        name
      }
    }
  }
`;

export function ReportPage(): JSX.Element {
  const { loading, error, data } = useQuery<GraphqlData>(graphqlQuery);

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;

  if (!data) {
    return <NoDataToDisplay />;
  }

  return (
    <>
      <ReportFiles reportFiles={data.report.reportFiles} />
      {/* <LastReport reportFiles={data.report.reportFiles} /> */}
    </>
  );
}

interface ReportFilesProps {
  reportFiles: GraphqlSchema.ReportFile[];
}

function LastReport(props: ReportFilesProps): JSX.Element | null {
  if (!props.reportFiles.length) {
    return null;
  }
  return (
    <embed
      src={'https://virus.img.cas.cz/f/report-cogcz-tyden-38.pdf'}
      // src={prefixServerUrl(`/f/${props.reportFiles[props.reportFiles.length - 1].name}`)}
      width="500"
      height="375"
      type="application/pdf"
    ></embed>
  );
}

function ReportFiles(props: ReportFilesProps): JSX.Element | null {
  if (!props.reportFiles.length) {
    return null;
  }

  let prevYear = 0;

  const reportFiles = reverse([...props.reportFiles]);

  return (
    <Box>
      <H2>Reporty</H2>

      {reportFiles.map((reportFile) => {
        const match = reportFile.name.match(/report-cogcz(-rok-(\d+))?-tyden-(\d+)/);
        let year: number;
        let week: number;

        if (match) {
          year = (match[2] ? parseInt(match[2]) : 21) + 2000;
          week = parseInt(match[3]);
        } else {
          year = 0;
          week = 0;
        }

        const yearChanged = prevYear !== year;
        prevYear = year;

        const readableName = week ? `COG-CZ Report ${week} týden.pdf` : reportFile.name;

        return (
          <>
            {yearChanged && (
              <H3
                style={{
                  borderBottom: '1px solid #ccc',
                  padding: '5px 0px',
                  margin: '20px 0 10px 0',
                }}
              >
                {year}
              </H3>
            )}

            <AnchorButton
              key={reportFile.name}
              className="margin-left-10 margin-bottom-10"
              icon="download"
              href={prefixServerUrl(`/f/${reportFile.name}`)}
              target="_blank"
              rel="noreferrer"
              outlined
            >
              {readableName} ({humanSize(reportFile.size, 0)})
            </AnchorButton>
          </>
        );
      })}
    </Box>
  );
}

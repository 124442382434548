const HUMAN_SIZE_UNITS = ['', 'KB', 'MB', 'GB', 'TB'];
// Number must be parsed because of
// 1.0.toFixed(2) => 1.00
// parseFloat(1.00) => 1

export function humanSize(value: number, fixed = 3): string {
  if (value === 0) {
    return '0';
  }

  const base = 1000;
  const exponent = Math.floor(Math.log(value) / Math.log(base));
  const human = value / base ** exponent;
  const mark = HUMAN_SIZE_UNITS[exponent];

  return `${parseFloat(human.toFixed(fixed))}${mark}`;
}

import { H3 } from '@blueprintjs/core';
import React from 'react';
import { Box } from '../../components/box';
import { ExternalLink } from '../../components/links';
import { Title } from '../../components/title';
import { Mentions } from './mentions';
import { People } from './people';

export function AboutPage(): JSX.Element {
  return (
    <>
      <Title>O COG-CZ</Title>

      <People />

      <Mentions />

      {/* <History /> */}

      <Box>
        <H3>Zdroje</H3>

        <ul>
          <li>
            <ExternalLink href="https://herv.img.cas.cz">
              Human Endogenous Retrovirus Database
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://cov-lineages.org">PANGO lineages</ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://nextstrain.org">Nextstrain</ExternalLink>
          </li>
          {/* <li>
            <ExternalLink href="https://apify.com/covid-19">APIFY covid-19</ExternalLink>
          </li> */}
        </ul>
      </Box>
    </>
  );
}

import { useEffect } from 'react';
import ReactGA, { EventArgs } from 'react-ga';
import { useHistory } from 'react-router-dom';
import { config } from './config';

ReactGA.initialize(config.gaTrackingCode);

export function Tracking(): null {
  const { listen, location } = useHistory();

  useEffect(() => {
    listen((location) => {
      ReactGA.pageview(location.pathname + location.search);
    });

    ReactGA.pageview(location.pathname + location.search);
  }, [listen]);

  return null;
}

export function trackEvent(params: EventArgs): void {
  ReactGA.event(params);
}

export function externalLinkEvent(label: string): void {
  ReactGA.outboundLink({ label }, () => {
    return;
  });
}

export function trackCurrentLocation(): void {
  ReactGA.pageview(window.location.pathname + window.location.search);
}

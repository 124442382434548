import { AboutPage } from './pages/about/aboutPage';
import { HomePage } from './pages/home/homePage';
import { LineagesPage } from './pages/lineages/lineagesPage';
import { ReportPage } from './pages/report/reportPage';
import { SamplePage } from './pages/sample/samplePage';
import { SamplesMapPage } from './pages/samples/mapPage';
import { SamplesPage } from './pages/samples/samplesPage';
import { UploadPage } from './pages/uploads/uploadPage';
import { VariantsPage } from './pages/variants/variantsPage';

interface TopMenuRoutes {
  path: string;
  label: string;
}

interface Route {
  path: string;
  exact?: boolean;
  component: () => JSX.Element;
}

export const topMenuRoutes: TopMenuRoutes[] = [
  { path: '/samples', label: 'Vzorky' },
  { path: '/variants', label: 'Varianty' },
  { path: '/lineages', label: 'Linie viru' },
  { path: '/report', label: 'Report' },
  { path: '/about', label: 'O nás' },
];

export const routes: Route[] = [
  {
    path: '/',
    exact: true,
    component: HomePage,
  },
  {
    path: '/samples',
    component: SamplesPage,
    exact: true,
  },
  {
    path: samplePath(':id'),
    component: SamplePage,
  },
  {
    path: '/about',
    component: AboutPage,
  },
  {
    path: '/lineages',
    component: LineagesPage,
  },
  {
    path: '/blog',
    component: VariantsPage,
  },
  {
    path: '/variants',
    component: VariantsPage,
  },
  {
    path: '/report/:tab',
    component: ReportPage,
  },
  {
    path: '/report',
    component: ReportPage,
    exact: true,
  },
  {
    path: '/samples-map',
    component: SamplesMapPage,
    exact: true,
  },
  {
    path: '/upload-sample',
    component: UploadPage,
    exact: true,
  },
  // {
  //   path: '/terms-of-use',
  //   component: TermsOfUsePage,
  // },
];

export function samplePath(id: string): string {
  return `/samples/${id}`;
}

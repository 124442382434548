import { ApolloProvider } from '@apollo/client';
import React, { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { graphqlClient } from '../config';
import { Tracking } from '../tracking';
import './app.css';
import { Content } from './content';
import { HeaderWithRouter } from './header';
import { Themed } from './theme';

export function App() {
  return (
    <ApolloProvider client={graphqlClient}>
      <GoogleReCaptchaProvider
        reCaptchaKey="6LfdrZkdAAAAAHzgM79QOzficzosn1lBmeKv6Ylz"
        language="cs"
        useRecaptchaNet={false}
        useEnterprise={false}
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'head', // optional, default to "head", can be "head" or "body",
          nonce: undefined, // optional, default undefined
        }}
      >
        <BrowserRouter>
          <Themed>
            <ScrollToTop />
            <Tracking />
            <HeaderWithRouter />
            <Content />
          </Themed>
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    </ApolloProvider>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

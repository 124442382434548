import { Callout } from '@blueprintjs/core';
import React from 'react';

export function NoDataToDisplay() {
  return (
    <Callout intent="warning" icon="info-sign">
      No data to display
    </Callout>
  );
}

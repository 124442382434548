/**
 * Modified version of https://github.com/clementbat/treemap
 */

import { getMaximum, getMinimum, roundValue, sumReducer } from './utils';

export interface TreemapDataItem {
  value: number;
  color?: string;
  label?: string;
}

export interface TreemapParams {
  data: TreemapDataItem[];
  width: number;
  height: number;
}

interface RectangleDataItem {
  x: number;
  y: number;
  width: number;
  height: number;
  data: TreemapDataItem;
}

interface Rectangle {
  data: RectangleDataItem[];
  xBeginning: number;
  yBeginning: number;
  totalWidth: number;
  totalHeight: number;
}

export function getTreemap({ data, height, width }: TreemapParams): RectangleDataItem[] {
  const rectangle: Rectangle = {
    data: [],
    xBeginning: 0,
    yBeginning: 0,
    totalWidth: width,
    totalHeight: height,
  };
  const initialData = data;

  function worstRatio(row: number[], width: number) {
    const sum = row.reduce(sumReducer, 0);
    const rowMax = getMaximum(row);
    const rowMin = getMinimum(row);

    return Math.max((width ** 2 * rowMax) / sum ** 2, sum ** 2 / (width ** 2 * rowMin));
  }

  const getMinWidth = () => {
    if (rectangle.totalHeight ** 2 > rectangle.totalWidth ** 2) {
      return { value: rectangle.totalWidth, vertical: false };
    }
    return { value: rectangle.totalHeight, vertical: true };
  };

  const layoutRow = (row: number[], width: number, vertical: boolean) => {
    const rowHeight = row.reduce(sumReducer, 0) / width;

    row.forEach((rowItem) => {
      const rowWidth = rowItem / rowHeight;
      const { xBeginning } = rectangle;
      const { yBeginning } = rectangle;

      let data: Rectangle['data'][number];
      if (vertical) {
        data = {
          x: xBeginning,
          y: yBeginning,
          width: rowHeight,
          height: rowWidth,
          data: initialData[rectangle.data.length],
        };
        rectangle.yBeginning += rowWidth;
      } else {
        data = {
          x: xBeginning,
          y: yBeginning,
          width: rowWidth,
          height: rowHeight,
          data: initialData[rectangle.data.length],
        };
        rectangle.xBeginning += rowWidth;
      }

      rectangle.data.push(data);
    });

    if (vertical) {
      rectangle.xBeginning += rowHeight;
      rectangle.yBeginning -= width;
      rectangle.totalWidth -= rowHeight;
    } else {
      rectangle.xBeginning -= width;
      rectangle.yBeginning += rowHeight;
      rectangle.totalHeight -= rowHeight;
    }
  };

  const layoutLastRow = (rows: number[], children: number[], width: number) => {
    const { vertical } = getMinWidth();
    layoutRow(rows, width, vertical);
    layoutRow(children, width, vertical);
  };

  const squarify = (children: number[], row: number[], width: number): void => {
    if (children.length === 1) {
      return layoutLastRow(row, children, width);
    }

    const rowWithChild = [...row, children[0]];

    if (row.length === 0 || worstRatio(row, width) >= worstRatio(rowWithChild, width)) {
      children.shift();
      return squarify(children, rowWithChild, width);
    }
    layoutRow(row, width, getMinWidth().vertical);

    return squarify(children, [], getMinWidth().value);
  };

  // validateArguments({ data, width, height });

  const totalValue = data.map((dataPoint) => dataPoint.value).reduce(sumReducer, 0);
  const dataScaled = data.map((dataPoint) => (dataPoint.value * height * width) / totalValue);

  squarify(dataScaled, [], getMinWidth().value);

  return rectangle.data.map((dataPoint) => ({
    ...dataPoint,
    x: roundValue(dataPoint.x),
    y: roundValue(dataPoint.y),
    width: roundValue(dataPoint.width),
    height: roundValue(dataPoint.height),
  }));
}

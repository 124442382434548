import { H3 } from '@blueprintjs/core';
import React from 'react';
import { Box } from '../../components/box';
import { ExternalLink } from '../../components/links';

export function Mentions(): JSX.Element {
  return (
    <Box>
      <H3>Napsali o nás</H3>

      <ul>
        <li>
          <ExternalLink href="https://tv.idnes.cz/rozstrel/rozstrel-jan-paces-cely-zaznam.V210323_130507_idnestv_krr">
            Očkujme i děti, jinak může virus zmutovat do drsnější formy - tv.idnes.cz
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="https://plus.rozhlas.cz/bez-sekvenovani-koronaviru-muze-prijit-vnivec-ockovani-i-promorovani-vedci-ted-8455164">
            Bez sekvenování koronaviru může přijít vniveč očkování i promořování - plus.rozhlas.cz
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="https://www.irozhlas.cz/zpravy-domov/cesko-vedci-koronavirus-mutace-vyzkum-databaze-financovani_2103261119_tzr">
            Vědci z Akademie věd mapují změny koronaviru a vývoj mutací - irozhlas.cz
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="https://www.seznamzpravy.cz/clanek/stat-zaspal-experti-na-virus-proto-zkoumaji-mutace-vlastnimi-silami-145091">
            Experti na virus zkoumají mutace vlastními silami - seznamzpravy.cz
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="https://www.irozhlas.cz/veda-technologie/veda/britska-jihoafricka-brazilska-varianta-mutace_2103290500_jab">
            Co umí nové varianty koronaviru - irozhlas.cz
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="https://www.nzip.cz/clanek/1084-covid-19-nove-varianty-koronaviru">
            COVID-19: nové varianty koronaviru - nzip.cz
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="https://www.irozhlas.cz/zpravy-domov/podcast-vinohradska-12-mutace-koronavirus-bocek_2104060600_bar">
            Jak vznikají a co umí nové varianty viru - irozhlas.cz
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="https://www.irozhlas.cz/zpravy-domov/covid-varianty-mutace-britska-jihoafricka-brazilska_2104080600_jab">
            Různé varianty, stejné mutace. Vědci se přou o to, jestli už koronaviru docházejí nápady
            - irozhlas.cz
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="https://video.aktualne.cz/dvtv/paces-covid-mel-volne-pole-muze-dal-mutovat-rozvolnil-bych-p/r~da847c7298a911eb9f15ac1f6b220ee8/">
            Covid měl volné pole, může dál mutovat, rozvolnil bych při tisíci nakažených -
            aktualne.cz
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="https://zpravy.aktualne.cz/zahranici/zivot-v-pandemii-ruth-tachezy-o-budoucim-vyvoji-mutaci-a-sit/r~be00641aa35a11eb89ccac1f6b220ee8/">
            Virus domutuje a začne slábnout, ale kdy? - aktualne.cz
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="https://www.avcr.cz/cs/veda-a-vyzkum/biologie-a-lekarske-vedy/Prehledne-Nejznamejsi-mutace-viru-SARS-CoV-2-v-Ceske-republice/">
            Nejznámější mutace viru SARS-CoV-2 v České republice - avcr.cz
          </ExternalLink>
        </li>
      </ul>
    </Box>
  );
}

import { Filler, Legend, Tooltip } from 'chart.js';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  ChartConfiguration,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
} from 'chart.js';
import React, { useContext, useEffect, useRef } from 'react';
import { ThemeContext } from '../app/theme';
// import 'chartjs-chart-treemap';

interface RenderChartProps {
  height?: number;
  options: ChartConfiguration;
  configKey?: string | number;
}

interface DrawParams extends RenderChartProps {
  canvasRef: React.RefObject<HTMLCanvasElement>;
}

if (Chart.defaults.animation) {
  Chart.defaults.animation.duration = 1;
}

Chart.register(
  BarController,
  LineController,
  BarElement,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
  Filler,
);

export function RenderChart(props: RenderChartProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    const chart = draw({ ...props, canvasRef });

    if (containerRef.current && canvasRef.current) {
      containerRef.current.style.height = `${canvasRef.current.height}px`;
    }

    if (chart) {
      return () => {
        chart.destroy();
      };
    }
  }, [themeContext.theme, props.configKey]);

  return (
    <div ref={containerRef}>
      <canvas ref={canvasRef} height={props.height} />
    </div>
  );
}

function draw(params: DrawParams) {
  if (!params.canvasRef.current) {
    return;
  }

  const context = params.canvasRef.current.getContext('2d');

  if (!context) {
    return;
  }

  return new Chart(context, params.options);
}

import { gql } from '@apollo/client';
import { GraphqlEdgeData } from '../../interfaces';
import { GraphqlSchema } from '@covd/lib';

export interface GraphqlData {
  samples: GraphqlEdgeData<
    Pick<
      GraphqlSchema.Sample,
      'id' | 'locationLabel' | 'collectionDate' | 'gender' | 'lineage' | 'age'
    >
  >;
}

export const graphqlQuery = gql`
  query GetSamples(
    $accessionId: String
    $lineage: String
    $location: String
    $laboratory: String
    $gender: String
    $ageLte: Int
    $ageGte: Int
    $collectionDateLte: Date
    $collectionDateGte: Date
    $page: Int!
  ) {
    samples(
      filter: {
        accessionId: $accessionId
        lineage: $lineage
        location: $location
        laboratory: $laboratory
        gender: $gender
        ageLte: $ageLte
        ageGte: $ageGte
        collectionDateLte: $collectionDateLte
        collectionDateGte: $collectionDateGte
      }
      pagination: { page: $page }
    ) {
      pageInfo {
        count
        page
        limit
        count
      }
      edges {
        id
        locationLabel
        collectionDate
        gender
        lineage
        age
      }
    }
  }
`;

import { gql, useQuery } from '@apollo/client';
import { H3 } from '@blueprintjs/core';
import { GraphqlSchema } from '@covd/lib';
import { ChartDataset } from 'chart.js';
import * as _ from 'lodash';
import { groupBy, sum, sumBy } from 'lodash';
import React from 'react';
import { Box } from '../../components/box';
import { Error } from '../../components/error';
import { Loading } from '../../components/loading';
import { NoDataToDisplay } from '../../components/noDataToDisplay';
import { RenderChart } from '../../components/renderChart';
import { getLineageColor, getLineageColorIndex } from '../../utils/lineageColor';
import { ControllerProps } from './controller';

interface GraphqlData {
  lineagesCountsInTime: GraphqlSchema.LineagesCountsInTime[];
}

interface GraphqlVariables {
  groupBy: GraphqlSchema.LineagesCountsInTimeGroupBy;
  range: GraphqlSchema.LineagesCountsInTimeRange;
}

const graphqlQuery = gql`
  query GetLineagesCountsInTime(
    $groupBy: LineagesCountsInTimeGroupBy!
    $range: LineagesCountsInTimeRange
  ) {
    lineagesCountsInTime(groupBy: $groupBy, range: $range) {
      id
      lineage
      groupBy
      group
      count
      countRelativeWithinGroup
    }
  }
`;

export function CountsInTime(props: ControllerProps): JSX.Element {
  const { loading, error, data } = useQuery<GraphqlData, GraphqlVariables>(graphqlQuery, {
    variables: {
      groupBy:
        props.state.groupBy === 'month'
          ? GraphqlSchema.LineagesCountsInTimeGroupBy.MONTH
          : GraphqlSchema.LineagesCountsInTimeGroupBy.WEEK,
      range: {
        from: props.state.range.from?.toISOString(),
        to: props.state.range.to?.toISOString(),
      },
    },
  });

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;

  if (!data?.lineagesCountsInTime?.length) {
    return <NoDataToDisplay />;
  }

  const otherItems: GraphqlSchema.LineagesCountsInTime[] = [];

  let items = data.lineagesCountsInTime.filter((item) => {
    const colorIndex = getLineageColorIndex(item.lineage);

    if (Number.isFinite(colorIndex)) {
      return true;
    } else {
      otherItems.push(item);
      return false;
    }
  });

  const datasets: ChartDataset[] = [];

  // items = items.map((item) => {
  //   return {
  //     ...item,
  //     lineage: Number.isFinite(getLineageColorIndex(item.lineage)) ? item.lineage : 'Ostatní',
  //   };
  // });

  if (props.state.selected.length) {
    items = items.filter((item) =>
      props.state.selected.find((selectedItem) => selectedItem.name === item.lineage),
    );
  }

  const totalCount = _.sumBy(items, 'count');
  const groupedByLineage = _.groupBy(
    _.sortBy(items, (item) => getLineageColorIndex(item.lineage)),
    'lineage',
  );

  groupedByLineage['Ostatni'] = Object.values(groupBy(otherItems, 'group')).map((items) => {
    return {
      count: _.sumBy(items, 'count'),
      group: items[0].group,
      groupBy: items[0].groupBy,
      id: '1',
      lineage: 'Ostatni',
      countRelativeWithinGroup: _.sumBy(items, 'countRelativeWithinGroup'),
    };
  });

  for (const [lineage, lineageItems] of Object.entries(groupedByLineage)) {
    const lineageTotalCount = _.sumBy(lineageItems, 'count');

    if (!lineageTotalCount) {
      continue;
    }

    if (!props.state.selected.length && props.state.minRepresentation) {
      const minCount = totalCount * (props.state.minRepresentation / 100);

      if (lineageTotalCount < minCount) {
        continue;
      }
    }

    const color = getLineageColor(lineage);

    const dataset: ChartDataset = {
      data: [],
      label: `${lineage} (${lineageTotalCount.toLocaleString()}x)`,
      tension: 0.1,
      backgroundColor: color,
      borderColor: color,
      fill: true,
    };

    let prevCount = 0;

    lineageItems.forEach((item) => {
      let y = 0;

      if (props.state.chartType === 'absolute') {
        y = item.count;
      } else if (props.state.chartType === 'cumulative') {
        y = prevCount + item.count;
        prevCount = y;
      } else if (props.state.chartType === 'relative') {
        const groupItems = items.filter(
          (otherItem) => otherItem.groupBy === item.groupBy && otherItem.group === item.group,
        );

        // This is very inefficient but dataset is not very large
        const totalCountWithGroup = _.sumBy(groupItems, 'count');

        y = (item.count / totalCountWithGroup) * 100;
      }

      dataset.data.push({
        y,
        x: item.group as any,
      });
    });

    datasets.push(dataset);
  }

  const labels = _.uniq(items.map((item) => item.group));

  return (
    <Box>
      <H3>
        Linie SARS-CoV-2 detekované v Česku ({props.state.range?.from?.toLocaleDateString('cs')} -{' '}
        {props.state.range?.to?.toLocaleDateString('cs')})
      </H3>

      <RenderChart
        configKey={props.state.key}
        options={{
          type: 'line',
          data: {
            datasets: datasets,
            labels: labels,
          },
          options: {
            plugins: {
              legend: {
                position: 'bottom',
              },
              tooltip: {
                mode: 'x',
              },
            },
            scales: {
              x: {
                // type: 'time',
                // time: { unit: 'month' },
                stacked: true,
                grid: {
                  display: false,
                },
              },
              y: {
                stacked: true,
                max: props.state.chartType === 'relative' ? 100 : undefined,
              },
            },
          },
        }}
      />
    </Box>
  );
}

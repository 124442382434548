import { Button, Classes, Navbar } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Tooltip2 } from '@blueprintjs/popover2';
import React, { useContext } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import logoSmall from '../assets/logo_small.png';
import { topMenuRoutes } from '../routes';
import './header.css';
import { ThemeContext } from './theme';

export function Header(): JSX.Element {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const themeIcon = theme === 'light' ? IconNames.MOON : IconNames.FLASH;

  return (
    <Navbar className={Classes.FOCUS_DISABLED}>
      <div className="header">
        <Navbar.Group align="right">
          <Tooltip2 content="Změnit schéma" placement="bottom">
            <Button icon={themeIcon} onClick={toggleTheme} minimal></Button>
          </Tooltip2>
        </Navbar.Group>

        <Navbar.Group align="left">
          <Navbar.Heading>
            <NavLink to="/" className="header__logo" exact>
              <img src={logoSmall} alt="Logo" />
              COG-CZ
            </NavLink>
          </Navbar.Heading>

          <Navbar.Divider />

          {topMenuRoutes.map((route) => (
            <NavLink
              key={route.path}
              className="header__route"
              to={route.path}
              activeClassName="header__route--active"
            >
              {route.label}
            </NavLink>
          ))}
        </Navbar.Group>
      </div>
    </Navbar>
  );
}

export const HeaderWithRouter = withRouter(Header);

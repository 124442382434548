import { debounce } from 'lodash';
import { trackCurrentLocation } from '../../tracking';

export interface FormParams<T> {
  state: FormState<T>;
  dispatch: React.Dispatch<FormAction<keyof T>>;
}

export type FormAction<T> =
  | { action: 'setValue'; key: T; value: string | number }
  | { action: 'setValues'; values: { [key: string]: string | number | null | Date | undefined } }
  | { action: 'setPage'; value: number }
  | { action: 'reset' };

export type FormState<T> = Partial<T> & {
  page?: number;
  key?: number;
  focusOn?: string;
};

const additionValuesToUrl = ['page'];

function stateToUrl<T>(urlKeys: string[], state: FormState<T>) {
  const url = new URL(window.location.toString());
  url.search = '';

  [...additionValuesToUrl, ...urlKeys].forEach((key) => {
    const value = state[key as keyof FormState<T>];

    if ((typeof value === 'string' && value) || typeof value === 'number') {
      url.searchParams.set(key, value.toString());
    }
  });

  window.history.pushState({}, '', url.toString());

  trackCurrentLocation();
}

export function formReducer<T>(urlKeys: string[]) {
  return (state: FormState<T>, action: FormAction<keyof T>) => {
    let newState: FormState<T> = {};

    switch (action.action) {
      case 'setValue':
        newState = {
          ...state,
          [action.key]: action.value,
          focusOn: action.key.toString(),
          page: 1,
        };
        break;

      case 'setValues':
        newState = { ...state, ...action.values, page: 1 };
        break;

      case 'setPage':
        newState = { ...state, page: action.value };
        break;

      case 'reset':
        newState = {};
        newState.page = 1;
    }

    state.key = Date.now();

    // stateToUrl(urlKeys, state);

    return newState;
  };
}

export function textInputChange<T extends string>(
  dispatch: React.Dispatch<FormAction<T>>,
  wait?: number,
) {
  const func = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | React.FormEvent<HTMLInputElement>,
  ) => {
    dispatch({
      action: 'setValue',
      key: (event.target as any).name as T,
      value: (event.target as any).value,
    });
  };

  if (wait) {
    return debounce(func, wait);
  } else {
    return func;
  }
}

// export function radioInputChange<T extends string>(
//   dispatch: React.Dispatch<FormAction<T>>,
//   wait?: number,
// ) {
//   const func = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
//     dispatch({
//       action: 'setValue',
//       key: event.target.name as T,
//       value: event.target.value,
//     });
//   };

//   if (wait) {
//     return debounce(func, wait);
//   } else {
//     return func;
//   }
// }

export function numericInputChange<T extends string>(
  dispatch: React.Dispatch<FormAction<T>>,
  wait?: number,
) {
  const func = (
    valueAsNumber: number,
    _valueAsString: string,
    inputElement: HTMLInputElement | null,
  ) => {
    dispatch({
      action: 'setValue',
      key: inputElement?.name as T,
      value: valueAsNumber,
    });
  };

  if (wait) {
    return debounce(func, wait);
  } else {
    return func;
  }
}

export function pageChange<T extends string>(dispatch: React.Dispatch<FormAction<T>>) {
  return (page: number) => dispatch({ action: 'setPage', value: page });
}

export function resetForm<T extends string>(dispatch: React.Dispatch<FormAction<T>>) {
  return () => dispatch({ action: 'reset' });
}

export function setAutoFocus<T>(state: FormState<T>, key: keyof T): boolean {
  return state.focusOn === key;
}

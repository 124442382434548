import elixirLogo from '../../assets/elixir_logo_middle.png';
import imgLogo from '../../assets/img_logo_middle.png';
import hervdLogo from '../../assets/hervd_middle.png';
import szuLogo from '../../assets/szu_middle.png';
import bioxsysLogo from '../../assets/bioxsys_logo_middle.png';
import React from 'react';
import { Box } from '../../components/box';

export function Institutes(): JSX.Element {
  return (
    <Box>
      <div className="institutes">
        <a href="https://www.img.cas.cz" target="_blank" rel="noreferrer">
          <img
            src={imgLogo}
            alt="Institute of Molecular Genetics of the Czech Academy of Sciences"
          />
        </a>

        <a href="https://www.elixir-czech.cz" target="_blank" rel="noreferrer">
          <img src={elixirLogo} alt="ELIXIR CZ" />
        </a>

        {/* <a href="https://herv.img.cas.cz" target="_blank" rel="noreferrer">
          <img src={hervdLogo} alt="Human Endogenous Retrovirus Database" />
        </a> */}

        <a
          href="http://www.szu.cz/narodni-referencni-laborator-pro-chripku"
          target="_blank"
          rel="noreferrer"
        >
          <img src={szuLogo} alt="Státní zdravotní ústav" />
        </a>

        <a href="https://bioxsys.cz" target="_blank" rel="noreferrer">
          <img src={bioxsysLogo} alt="Bioxsys" />
        </a>
      </div>
    </Box>
  );
}

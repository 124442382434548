export class SearchParams {
  url: URL;

  constructor() {
    this.url = new URL(window.location.toString());
  }

  getString(key: string, otherwise?: string): string | undefined {
    return this.url.searchParams.get(key) || otherwise;
  }

  getInt(key: string, otherwise?: number): number | undefined {
    const value = this.url.searchParams.get(key);

    if (value) {
      return parseInt(value);
    } else {
      return otherwise;
    }
  }

  getDate(key: string, otherwise?: Date): Date | undefined {
    const value = this.url.searchParams.get(key);

    if (value) {
      return new Date(value);
    } else {
      return otherwise;
    }
  }
}

import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Callout,
  Classes,
  FileInput,
  FormGroup,
  InputGroup,
  ProgressBar,
  TextArea,
} from '@blueprintjs/core';
import React, { useEffect, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Box } from '../../components/box';
import { config } from '../../config';

const createSampleUploadMutation = gql`
  mutation CreateSampleUpload(
    $name: String!
    $contact: String!
    $otherContact: String!
    $extraInfo: String!
    $recaptchaToken: String!
  ) {
    createSampleUpload(
      name: $name
      contact: $contact
      otherContact: $otherContact
      extraInfo: $extraInfo
      recaptchaToken: $recaptchaToken
    ) {
      objectName
      token
    }
  }
`;

const finishSampleUploadMutation = gql`
  mutation FinishSampleUpload($objectName: String!, $token: String!, $parts: Int!) {
    finishSampleUpload(objectName: $objectName, token: $token, parts: $parts)
  }
`;

export function UploadPage(): JSX.Element {
  const recaptcha = useGoogleReCaptcha();
  const nameRef = useRef<HTMLInputElement>(null);
  const contactRef = useRef<HTMLInputElement>(null);
  const otherContactRef = useRef<HTMLInputElement>(null);
  const extraInfoRef = useRef<HTMLTextAreaElement>(null);

  const [createSampleUpload, { data, error }] = useMutation<
    { createSampleUpload: { objectName: string; token: string } },
    {
      name: string;
      contact: string;
      otherContact: string;
      extraInfo: string;
      recaptchaToken: string;
    }
  >(createSampleUploadMutation, {
    onError(error) {
      console.error(error);
    },
  });

  const submit = async () => {
    if (!recaptcha.executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    if (!nameRef.current!.reportValidity() || !contactRef.current!.reportValidity()) {
      return;
    }

    const token = await recaptcha.executeRecaptcha();
    // console.log(token);

    createSampleUpload({
      variables: {
        name: nameRef.current!.value,
        contact: contactRef.current!.value,
        otherContact: otherContactRef.current!.value,
        extraInfo: extraInfoRef.current!.value,
        recaptchaToken: token,
      },
    });
  };

  // const data = {
  //   createSampleUpload: {
  //     objectName: 'sample-b9783d65-df67-429c-89ac-ee61478a6631',
  //     token:
  //       'bbc7fa760d81e8370597f0d37dcf9b6dc13f83938400782c98b9223b812cd4197ee52aa6a5c43987bb2baf4a98766f73',
  //     __typename: 'SampleUpload',
  //   },
  // };

  if (data) {
    console.log(data);

    return (
      <UploadFile
        objectName={data.createSampleUpload.objectName}
        token={data.createSampleUpload.token}
      />
    );
  }

  return (
    <Box>
      {error && (
        <Callout className="margin-bottom-20" intent="danger">
          {error.message}
        </Callout>
      )}

      <FormGroup
        helperText="Jméno vzorku"
        label="Název"
        labelFor="text-input"
        labelInfo="(povinné)"
      >
        <InputGroup placeholder="Název" inputRef={nameRef} required />
      </FormGroup>

      <FormGroup
        helperText="Email na odesílatele"
        label="Kontakt"
        labelFor="text-input"
        labelInfo="(povinné)"
      >
        <InputGroup placeholder="Kontakt" inputRef={contactRef} required />
      </FormGroup>

      <FormGroup helperText="Telefon/skype/slack/..." label="Další kontakt" labelFor="text-input">
        <InputGroup placeholder="Telefon/skype/slack/..." inputRef={otherContactRef} />
      </FormGroup>

      <FormGroup
        helperText="Dodatečné informace ke vzorku"
        label="Další informace"
        labelFor="text-input"
      >
        <TextArea placeholder="Dodatečné informace" rows={5} cols={50} inputRef={extraInfoRef} />
      </FormGroup>

      <FormGroup>
        <Button icon="send-message" intent="primary" onClick={submit}>
          Potvrdit a přejit k nahrání souboru
        </Button>
        <div className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>
          This site is protected by reCAPTCHA and the Google{' '}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>
      </FormGroup>
    </Box>
  );
}

const chunkSize = 10_000_000;

function UploadFile({ objectName, token }: { objectName: string; token: string }): JSX.Element {
  const [showProgress, setShowProgress] = useState(false);
  const [counter, setCounter] = useState(1);
  const [fileToBeUpload, setFileToBeUpload] = useState<File>();
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
  const [progress, setProgress] = useState(0);
  const [fileSize, setFileSize] = useState(0);
  const [chunkCount, setChunkCount] = useState(0);

  const [finishSampleUpload, { data, error }] = useMutation<
    { finishSampleUpload: string },
    {
      objectName: string;
      token: string;
      parts: number;
    }
  >(finishSampleUploadMutation, {
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (fileSize > 0) {
      fileUpload();
    }
  }, [fileToBeUpload, progress]);

  const resetChunkProperties = () => {
    setShowProgress(true);
    setProgress(0);
    setCounter(1);
    setBeginingOfTheChunk(0);
    setEndOfTheChunk(chunkSize);
  };

  const startUpload = (e: React.FormEvent<HTMLInputElement>) => {
    resetChunkProperties();
    const file = e.currentTarget.files![0];
    setFileSize(file.size);

    const totalCount =
      file.size % chunkSize === 0 ? file.size / chunkSize : Math.floor(file.size / chunkSize) + 1;
    setChunkCount(totalCount);

    setFileToBeUpload(file);
  };

  const fileUpload = () => {
    setCounter(counter + 1);
    if (counter <= chunkCount) {
      const chunk = fileToBeUpload!.slice(beginingOfTheChunk, endOfTheChunk);
      uploadChunk(chunk);
    }
  };

  const uploadChunk = async (chunk: Blob) => {
    try {
      const data = new FormData();
      data.append('file', chunk);

      const { body, status } = await fetch(
        `${config.serverUrl}/samples-uploads/${objectName}?part=${counter}&token=${token}`,
        {
          method: 'PUT',
          body: data,
        },
      );

      if (status === 200) {
        setBeginingOfTheChunk(endOfTheChunk);
        setEndOfTheChunk(endOfTheChunk + chunkSize);

        if (counter === chunkCount) {
          await uploadCompleted();
        } else {
          const percentage = (counter / chunkCount) * 100;
          setProgress(percentage);
        }
      } else {
        console.log('Error occurred', body);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const uploadCompleted = async () => {
    finishSampleUpload({
      variables: {
        objectName,
        token,
        parts: counter,
      },
    });

    setProgress(100);
  };

  if (error) {
    return (
      <Callout className="margin-bottom-20" intent="danger">
        {error.message}
      </Callout>
    );
  }

  if (data) {
    return (
      <Box>
        File will be shortly available <a href={data.finishSampleUpload}>at this link</a>
      </Box>
    );
  }

  return (
    <Box>
      <div className="margin-bottom-20">
        <FileInput onInputChange={startUpload} />
      </div>

      {showProgress && (
        <div>
          {progress.toFixed(0)} %
          <ProgressBar value={progress / 100} intent="primary" stripes={false} animate />
        </div>
      )}
    </Box>
  );
}

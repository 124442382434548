const colors = {
  'B.1': '#A6CEE3',
  'P.1': '#A6CEE3',
  'P.2': '#A6CEE3',
  'B.1.177': '#FB9A99',
  'B.1.1.7': '#F3444B',
  'B.1.160': '#FDBF6F',
  'B.1.351': '#FF7F00',
  'B.1.351.2': '#FF7F00',
  'B.1.351.5': '#FF7F00',
  'B.1.525': '#C65911',
  Omicron: '#FFDF00',
  'B.1.1.529': '#FFDF01',
  'BA.1': '#FFDF02',
  'BA.1.1': '#FFDF5F',
  'BA.1.1.1': '#FFDF5F',
  'BA.1.1.2': '#FFDF5F',
  'BA.1.1.7': '#FFDF5F',
  'BA.1.1.14': '#FFDF5F',
  'BA.1.1.15': '#FFDF5F',
  'BA.1.1.18': '#FFDF5F',
  'BA.1.5': '#FFDF5F',
  'BA.1.14': '#FFDF5F',
  'BA.1.15': '#FFDF5F',
  'BA.1.15.1': '#FFDF5F',
  'BA.1.17': '#FFDF87',
  'BA.1.17.2': '#FFDF88',
  'BA.1.18': '#FFDF89',
  'BA.2': '#DE7DB6',
  'BA.2.1': '#DE7DB7',
  'BA.2.3': '#DE7DB7',
  'BA.2.3.2': '#DE7DB7',
  'BA.2.3.9': '#DE7DB7',
  'BA.2.3.16': '#DE7DB7',
  'BA.2.5': '#DE7DB7',
  'BA.2.6': '#DE7DB7',
  'BA.2.7': '#DE7DB7',
  'BA.2.8': '#DE7DB7',
  'BA.2.9': '#AB4979',
  'BA.2.9.2': '#AB4979',
  'BA.2.9.3': '#AB4979',
  'BA.2.10': '#AB4979',
  'BA.2.12': '#AB4980',
  'BA.2.12.1': '#AB4980',
  'BG.5': '#AB4980',
  'BA.2.13': '#AB4980',
  'BA.2.14': '#AB4981',
  'BA.2.15': '#AB4980',
  'BA.2.17': '#AB4981',
  'BA.2.18': '#AB4991',
  'BA.2.22': '#941751',
  'BA.2.23': '#941751',
  'BA.2.25': '#941751',
  'BA.2.25.1': '#941751',
  'BA.2.27': '#941751',
  'BA.2.29': '#941751',
  'BA.2.36': '#941751',
  'BA.2.37': '#941751',
  'BA.2.38': '#941759',
  'BA.2.40.1': '#8EFA00',
  'BA.2.41': '#8EFA00',
  'BA.2.44': '#8EFA00',
  'BA.2.45': '#8EFA00',
  'BA.2.47': '#8EFA00',
  'BA.2.48': '#8EFA00',
  'BA.2.50': '#8EFA00',
  'BA.2.51': '#8EFA00',
  'BA.2.52': '#8EFA00',
  'BA.2.54': '#8EFA00',
  'BA.2.56': '#8EFA00',
  'BA.2.65': '#33A02C',
  'BA.2.67': '#33A02C',
  'BA.2.70': '#33A02C',
  'BA.2.71': '#33A02C',
  'BA.2.72': '#33A02C',
  'BA.2.76': '#33A02C',
  'BA.2.77': '#33A02C',
  'BA.4': '#00FB92',
  'BA.4.1': '#00FB92',
  'BA.4.6': '#00FB92',
  'BA.5': '#0096FF',
  'BA.5.1': '#0096FF',
  'BA.5.1.1': '#0096FF',
  'BA.5.1.2': '#0096FF',
  'BA.5.1.3': '#0096FF',
  'BA.5.1.4': '#0096FF',
  'BA.5.1.9': '#0096FF',
  'BA.5.1.10': '#0096FF',
  'BA.5.2': '#0011FF',
  'BA.5.2.1': '#0011FF',
  'BA.5.2.2': '#0011FF',
  'BF.1': '#0011FF',
  'BF.2': '#0011FF',
  'BF.5': '#0011FF',
  'BF.7': '#0011FF',
  'BF.14': '#0011FF',
  'BA.5.2.3': '#0011FF',
  'BA.5.3': '#0032B3',
  'BA.5.3.1': '#0032B3',
  'BE.1': '#0032B3',
  'BE.1.1': '#0032B3',
  'BA.5.5': '#00CCFF',
  'BA.5.6': '#00CCFF',
  'BA.5.8': '#00CCFF',
  'BA.5.9': '#00CCFF',
  'BA.5.10': '#00CCFF',
  'B.1.1.318': '#1F78B4',
  'AZ.2': '#338CC8',
  'B.1.617.2': '#CAB2D6',
  'AY.1': '#9053D1',
  'AY.2': '#9053D1',
  'AY.3': '#9053D1',
  'AY.4': '#9053D1',
  'AY.4.1': '#9053D1',
  'AY.4.2': '#9053D1',
  'AY.4.2.3': '#9053D1',
  'AY.4.3': '#9053D1',
  'AY.4.4': '#9053D1',
  'AY.4.5': '#9053D1',
  'AY.4.6': '#9053D1',
  'AY.4.13': '#9053D1',
  'AY.5': '#9053D1',
  'AY.5.2': '#9053D1',
  'AY.6': '#9053D1',
  'AY.7': '#9053D1',
  'AY.7.1': '#9053D1',
  'AY.7.2': '#9053D1',
  'AY.8': '#9053D1',
  'AY.10': '#9053D1',
  'AY.11': '#9053D1',
  'AY.12': '#9053D1',
  'AY.17': '#9053D1',
  'AY.18': '#9053D1',
  'AY.19': '#9053D1',
  'AY.20': '#9053D1',
  'AY.20.1': '#9053D1',
  'AY.21': '#9053D1',
  'AY.23': '#9053D1',
  'AY.25': '#4D4D4D',
  'AY.26': '#4D4D4D',
  'AY.33': '#9053D1',
  'AY.34': '#9053D1',
  'AY.36': '#9053D1',
  'AY.38': '#9053D1',
  'AY.39': '#9053D1',
  'AY.41': '#9053D1',
  'AY.44': '#9053D1',
  'AY.46': '#9053D1',
  'AY.46.2': '#9053D1',
  'AY.46.6': '#9053D1',
  'AY.47': '#9053D1',
  'AY.51': '#9053D1',
  'AY.84': '#9053D1',
  'AY.98': '#9053D1',
  'AY.98.1': '#9053D1',
  'AY.106': '#9053D1',
  'AY.112': '#9053D1',
  'AY.113': '#9053D1',
  'AY.116': '#9053D1',
  'AY.119': '#9053D1',
  'AY.121': '#9053D1',
  'AY.121.1': '#9053D1',
  'AY.123': '#9053D1',
  'AY.124': '#9053D1',
  'AY.125': '#9053D1',
  'AY.126': '#9053D1',
  'AY.127': '#9053D1',
  'AY.129': '#9053D1',
  'AY.16': '#502E75',
  'AY.9': '#FF2F92',
  'AY.9.1': '#FF2F92',
  'AY.9.2': '#FF2F92',
  'AY.42': '#D4FB79',
  'AY.43': '#B2DF8A',
  'AY.43.2': '#B2DF8A',
  'AY.68': '#0096FF',
  'AY.122': '#9053D1',
  'AY.122.1': '#9053D1',
  'B.1.1': '#FFFF99',
  'B.1.1.153': '#B15928',
  'C.36': '#ABA200',
  'C.36.3': '#DDD100',
  'C.37': '#0432FF',
  Other: '#999999',
  'B.1.258': '#999999',
  'B.1.258.3': '#999999',
  None: '#BBBBBB',
  XAD: '#0432FF',
  XM: '#0432FF',
  XQ: '#0432FF',
  XW: '#0432FF',
};

const lineages = Object.keys(colors);

const other = '#999999';

export function getLineageColor(lineage: string): string {
  return colors[lineage as keyof typeof colors] ?? other;
}

export function getLineageColorIndex(lineage: string): number {
  const index = lineages.indexOf(lineage);

  if (index === -1) {
    return Infinity;
  } else {
    return index;
  }
}

import { useQuery } from '@apollo/client';
import { H2, HTMLTable } from '@blueprintjs/core';
import { GraphqlSchema } from '@covd/lib';
import React, { useReducer } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '../../components/box';
import { Error } from '../../components/error';
import { FormParams, formReducer, FormState, pageChange } from '../../components/forms/formState';
import { hasPages, Pagination } from '../../components/forms/pagination';
import { BorderedLineage } from '../../components/lineages';
import { ExternalLink } from '../../components/links';
import { Loading } from '../../components/loading';
import { NoDataToDisplay } from '../../components/noDataToDisplay';
import { Title } from '../../components/title';
import { GraphqlPaginateData } from '../../interfaces';
import { samplePath } from '../../routes';
import { SearchParams } from '../../utils/searchParams';
import { translateGender } from '../../utils/translate';
import { Download } from './download';
import { Form } from './form';
import { GraphqlData, graphqlQuery } from './graphql';

const urlKeys: Array<keyof GraphqlSchema.SamplesFilter> = [
  'accessionId',
  'lineage',
  'location',
  'laboratory',
  'gender',
  'ageLte',
  'ageGte',
  'collectionDateLte',
  'collectionDateGte',
];

const reducer = formReducer<GraphqlSchema.SamplesFilter>(urlKeys);

export function SamplesPage(): JSX.Element {
  const searchParams = new SearchParams();

  const initialState: FormState<GraphqlSchema.SamplesFilter> = {
    accessionId: searchParams.getString('accessionId'),
    lineage: searchParams.getString('lineage'),
    location: searchParams.getString('location'),
    laboratory: searchParams.getString('laboratory'),
    gender: searchParams.getString('gender'),
    ageLte: searchParams.getInt('ageLte'),
    ageGte: searchParams.getInt('ageGte'),
    collectionDateLte: searchParams.getDate('collectionDateLte'),
    collectionDateGte: searchParams.getDate('collectionDateGte'),
    page: searchParams.getInt('page', 1),
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <Title>Vzorky</Title>
      <Form state={state} dispatch={dispatch} />
      <Download />
      <Table state={state} dispatch={dispatch} />
    </>
  );
}

function Table(props: FormParams<GraphqlSchema.SamplesFilter>) {
  const { loading, error, data } = useQuery<
    GraphqlData,
    GraphqlPaginateData<GraphqlSchema.SamplesFilter>
  >(graphqlQuery, {
    variables: {
      accessionId: props.state.accessionId,
      lineage: props.state.lineage,
      location: props.state.location,
      laboratory: props.state.laboratory,
      gender: props.state.gender,
      ageLte: props.state.ageLte,
      ageGte: props.state.ageGte,
      collectionDateLte: props.state.collectionDateLte,
      collectionDateGte: props.state.collectionDateGte,
      page: props.state.page,
    },
  });

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;

  const samples = data?.samples?.edges;

  if (!data || !samples || !samples.length) {
    return <NoDataToDisplay />;
  }

  return (
    <>
      <Box>
        <H2>Záznamy ({data.samples.pageInfo.count.toLocaleString()})</H2>

        <div className="overflow-x">
          <HTMLTable className="width-100" interactive condensed striped>
            <thead>
              <tr>
                <th>Id</th>
                <th>Datum</th>
                <th>Linie</th>
                <th>Pohlaví</th>
                <th>Věk</th>
                <th>Lokace</th>
              </tr>
            </thead>
            <tbody>
              {samples.map((sample) => (
                <tr key={sample.id}>
                  <td>
                    <Link to={samplePath(sample.id)}>{sample.id.split('-')[0]}</Link>
                  </td>
                  <td>{sample.collectionDate}</td>
                  <td>
                    <BorderedLineage lineage={sample.lineage} />
                  </td>
                  <td>{translateGender(sample.gender)}</td>
                  <td>{sample.age}</td>
                  <td>
                    {sample.locationLabel && (
                      <>
                        {sample.locationLabel}
                        <ExternalLink
                          icon="map"
                          className="margin-left-10"
                          href={`https://mapy.cz/zakladni?q=${encodeURIComponent(
                            sample.locationLabel,
                          )}`}
                        >
                          Mapy.cz
                        </ExternalLink>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </HTMLTable>
        </div>
      </Box>

      {hasPages(data.samples.pageInfo) && (
        <Box>
          <Pagination {...data?.samples.pageInfo} callback={pageChange(props.dispatch)} />
        </Box>
      )}
    </>
  );
}

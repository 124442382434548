import { Box } from '../../components/box';
import React from 'react';
import { H2, H3, Tag } from '@blueprintjs/core';
import { ExternalLink } from '../../components/links';
import variants from '../../assets/variants.png';
import b117 from '../../assets/b117.png';
import b1351 from '../../assets/b1351.png';
import b16172 from '../../assets/b16172.png';
import p1 from '../../assets/p1.png';
import omicronMutace from '../../assets/omicron_mutace.png';
import omicronEvropa from '../../assets/omicron_evropa.png';
import omicronVyvojMutaci from '../../assets/omicron_vyvoj_mutaci.png';
import omicronVyvojMutaciSpike from '../../assets/omicron_vyvoj_mutaci_spike.png';
import ucinostVakcin from '../../assets/ucinost_vakcin.png';

export function VariantsPage(): JSX.Element {
  return (
    <>
      <Box>
        <H2>Varianty viru SARS-CoV-2</H2>

        {/* <p className="text-columns-2">
          Všechny viry během času mutují (to platí i pro všechny živé organizmy), některé rychleji,
          některé pomaleji. Koronaviry patří mezi RNA viry, jejichž genetická informace je uložena v
          molekule RNA. Přestože mají opravné mechanizmy a mutace se v nich hromadí pomaleji než v
          jiných RNA virech, třeba chřipky, přesto mutují. A to rychlostí zhruba 25 mutací za rok.
          To znamená, že jak virus přeskakuje z člověka na člověka, zhruba v každém druhém až třetím
          hostiteli přibere nějakou novou mutaci k těm již existujícím. Mutace můžeme označovat
          různými způsoby, nyní je nejčastější toto schéma: <Tag minimal>S:E484K</Tag>, tedy mutace
          ve spike proteinu, kde je aminokyselina E (glutamová) na pozici 484 změněna na
          aminokyselinu K (lysin).
        </p> */}

        <div className="text-align-center">
          <img src={variants} alt="Nextrain varianty" width={900} />
          <p>
            Obrázek z{' '}
            <ExternalLink href="https://nextstrain.org/ncov/global?l=clock">
              https://nextstrain.org/ncov/global?l=clock
            </ExternalLink>
            . Jak se v čase hromadí jednotlivé mutace.
          </p>
        </div>

        <div className="text-columns-2">
          <p>
            Podle dopadu na vý voj epidemie se rozdě lují aktuální varianty viru SARS-CoV-2 do tří
            kategorií:
          </p>

          <p>
            Varianta hodná pozornosti (VOC) je varianta, kde máme experimentálně ověřená data o
            zvýšené přenositelnosti, závažnějším průběhu onemocnění, snížené neutralizaci
            protilátkami generovanými během předchozí infekce nebo očkování, snížení účinnosti
            léčby, snížení účinnosti vakcín, nebo selhání diagnostické detekce. V současnosti patří
            mezi Varianty hodné pozornosti (dle ECDC klasifikace) Delta (B.1.617.2 a AY.*) a Omicron
            (B.1.1.529, BA.*).
          </p>

          <p>
            Varianta hodná zájmu (VOI) je varianta, kde na základě mutací můžeme předpokládat změnu
            vazby viru na buňku, imunitní únik nebo sníženou účinnost léčby. Aktuálně nejsou
            definovány žádné Varianty hodné zájmu (dle ECDC klasifikace).
          </p>

          <p>
            Varianty sledované (VUM) jsou varianty, které se objevují ve větším množství během
            plošného sekvenování. O většině z nich zatím není dostatek informací, aby byly zařazeny
            do nějaké vyšší kategorie. Jejich šíření je však vhodné pečlivě monitorovat. Aktuálně se
            v této kategorii objevují varianty vzniklé rekombinací dřívějších variant souhrnně
            označované jako X*. VUM varianty dle ECDC jsou XF (poprvé objevený v UK - rekombinace
            Delta and BA.1 v S proteinu podobný Omicronu) a XD (poprvé objevený ve Francii a Dánsku
            - rekombinace Delta a BA.1 v S proteinu míchající mutace známé jak z Delty, tak z
            Omicronu).
          </p>

          {/* <p>
            Jednotlivé mutace nemusí ovlivnit vlastnosti viru, ale mohou. Například mohou zvýšit
            vazbu viru na buňku a tím zvýšit jeho infekčnost. Nebo mohou změnit tvar místa, proti
            kterému jsme si vyrobili protilátky a tím pádem jim uniknout. V případě, že se ve viru
            nahromadí několik mutací, které mají vliv na jeho biologické vlastnosti a tento virus se
            pak někde lokálně rozšíří, pojmenujeme viry s touto sadou mutací jako variantu,
            například <Tag minimal>B.1.1.7</Tag>. Obecně se nedoporučuje varianty spojovat s místem
            jejich vzniku nebo první detekce kvůli stigmatizaci daného regionu nebo státu, ale v
            médiích se pro svou jednoduchost právě toto označení vžilo.
          </p>

          <p>
            Podle toho, jak jsou jednotlivé varianty významné a jaký mají dopad na vývoj epidemie,
            označujeme je za „Variant of High Consequence“ - „varianty s významným následkem“, VOC
            „Variant of Concern“ – „varianta hodné pozornosti“, VOI „Variant of Interest“, tedy
            „varianta hodné zájmu“, a „Variant under monitoring“ – „varianta sledovaná“. Celosvětově
            existuje několik organizací (v Evropě je to ECDC, European Centre for Disease Prevention
            and Control), které se klasifikací virových variant zabývají, proto nemusí být přiřazení
            do dané skupiny úplně univerzální. link:{' '}
            <ExternalLink href="https://www.ecdc.europa.eu/en/covid-19/variants-concern">
              https://www.ecdc.europa.eu/en/covid-19/variants-concern
            </ExternalLink>
          </p>

          <p>
            Jako varianta s významným následkem zatím nebyla označena žádná známá varianta. To mimo
            jiné znamená, že ačkoliv se současné koronaviry liší od toho původního z Wu-chanu již v
            desítkách mutací, pořád se ještě jejich vlastnosti příliš nezměnily. Ale i malé změny
            mohou mít velký dopad, když se jedná o pandemii se stovkami miliónů nakažených.
          </p>

          <p>
            Varianta hodná pozornosti (VOC) je varianta, kde máme experimentálně ověřena data o
            zvýšené přenositelnosti, závažnějším průběhu onemocnění, snížení neutralizace
            protilátkami generovanými během předchozí infekce nebo očkování, snížené účinnosti léčby
            nebo vakcín anebo selhání diagnostické detekce. V současnosti ECDC jako VOC označeny
            varianty: <Tag minimal>B.1.1.7 </Tag>, <Tag minimal>B.1.1.7+E484K</Tag>,{' '}
            <Tag minimal>B.1.351</Tag>, <Tag minimal>P.1</Tag> a <Tag minimal>B.1.617.2</Tag>
          </p>

          <p>
            Varianta hodná zájmu (VOI) je varianta, kde na základě mutací můžeme předpokládat změnu
            vazby viru na buňku, imunitní únik nebo sníženou účinnost léčby či dihgnosti. Důsledkem
            těchto změn je lokální zvýšení množství dané varianty. Aktuální varianty hodné zájmu
            jsou: <Tag minimal>B.1.525</Tag>, <Tag minimal>B.1.427/B.1.429</Tag>,{' '}
            <Tag minimal>P.3</Tag>, <Tag minimal>B.1.616</Tag>, <Tag minimal>B.1.617.1</Tag>,{' '}
            <Tag minimal>B.1.617.3</Tag>, <Tag minimal>B.1.620</Tag> a <Tag minimal>B.1.621</Tag>.
          </p>

          <p>
            Varianty sledované jsou varianty, které se objevují ve větším množství během plošného
            sekvenování. O většině z nich zatím není dost informací, aby byly zařazeny do nějaké
            vyšší kategorie, ale protože se šíří, je vhodné je pečlivě monitorovat.
          </p> */}
        </div>
      </Box>

      <Box>
        <H3>Omicron (B.1.1.529)</H3>

        <div className="text-columns-2">
          <p>
            Zastoupení VOC varianty Omicron v Evropě roste od prosince 2021, v průběhu počátku roku
            2022 se tato varianta stala dominantní. S velkou pravděpodobností je tento rychlý nárůst
            způsoben únikem imunitnímu systému a protilátkám indukovaným předchozími variantami
            Sars-Cov-2 či očkováním ( Risk assessment for SARS-CoV-2 variant: Omicron VOC-21NOV-01
            (B.1.1.529) UK Health Security Agency 12 January 2022). Příčinou tohoto může být i
            nezvykle velké množství mutací, z nichž velká část se nachází na spike proteinu, kde
            vedou k silnější vazbě na lidský ACE2 receptor a současně ke snížení efektivity
            protilátek
          </p>
        </div>

        <div className="text-align-center">
          <img src={omicronMutace} alt="Omicron mutace" width={800} />
          <p>Charakteristické mutace varianty Omicron. Převzato z outbreak.info.</p>
        </div>

        <div className="text-align-center">
          <img src={omicronEvropa} alt="Zastoupení omicronz v Evropě" width={800} />
          <p>
            Zastoupení varianty Omicron v Evropě v 10. týdnu roku 2022. Převzato z ECDC Dashboard on
            Variants of interest and concern in the EU/EEA.
          </p>
        </div>

        <p>
          Varianta Omicron má více než 60 mutací ve srovnání s původní variantou SARS-CoV-2, což
          odpovídá více zmutovaným zástupcům varianty Delta.
        </p>

        <div className="text-align-center">
          <img src={omicronVyvojMutaci} alt="Omicron vývoj mutaci" width={800} />
          <p>Vývoj mutací. Převzato z nextstrain.org.</p>
        </div>

        <p>Na rozdíl od Delty má však Omicron výrazně více mutací ve spike proteinu.</p>

        <div className="text-align-center">
          <img
            src={omicronVyvojMutaciSpike}
            alt="Omicron vývoj mutaci ve spike proteinu"
            width={800}
          />
          <p>Vývoj mutací ve spike proteinu. Převzato z nextstrain.org.</p>
        </div>

        <div className="text-columns-2">
          <p>
            Varianta Omicron je minimálně stejně nakažlivá jako varianta Delta. Mimo rozsáhlé změny
            v receptor vazebné doméně Spike proteinu a silnější vazbě na ACE2 receptor, několik
            prací naznačuje, že Omicron může pro vstup do buňky využít také alternativní cestu skrze
            endosomy. Omicron se dokáže lépe replikovat v buňkách horních cest dýchacích in vitro.
          </p>

          <p>
            Jak in vitro neutralizační experimenty, tak data o účinnosti vakcín v ochraně proti
            symptomatickému onemocnění potvrzují, že varianta Omicron úspěšně uniká imunitnímu
            systému a protilátkám, které cílí předchozí varianty koronaviru. Účinnost vakcín je po
            dvou dávkách pro Omicron výrazně nižší než pro Deltu, dvacet týdnů po aplikaci druhé
            dávky ochrana téměř vyvane. Třetí dávka ji však vrací na vyšší hladinu a i po 10 týdnech
            zůstává nad 50 %. Data ze Spojeného království ukazují, že ochrana proti těžkému průběhu
            onemocnění a hospitalizaci je po třetí dávce vysoká a dosahuje 92% dva týdny po aplikaci
            třetí dávky a zůstává nad 80% i deset týdnů po aplikaci třetí dávky. ( Risk assessment
            for SARS-CoV-2 variant: Omicron VOC-21NOV-01 (B.1.1.529) UK Health Security Agency 12
            January 2022)
          </p>
        </div>

        <div className="text-align-center">
          <img src={ucinostVakcin} alt="Účinost vakcin pro omicron a deltu" width={800} />
          <p>
            Účinnost vakcín proti symptomatickému onemocnění po dvou dávkách vakcíny Pfizer
            (BNT162b2) a přeočkování třetí dávkou Pfizer nebo Moderna (mRNA-1273). Převzato z
            SARS-CoV-2 variants of concern and variants under investigation in England Technical
            briefing 34 14 January 2022.
          </p>
        </div>

        <div className="text-columns-2">
          <p>
            V předběžných studiích v UK se ukazuje nižší pravděpodobnost hospitalizace pacientů
            nakažených variantou Omicron v porovnání s Delta a to přibližně o 62 %. (SARS-CoV-2
            variants of concern and variants under investigation in England Technical briefing 33 23
            December 2021). Snížené riziko hospitalizace bylo pozorováno také v Jihoafrické
            republice. Dostupná data indikují, že snížení závažnosti onemocnění je důsledkem snížené
            virulence varianty Omicron, která byla pozorována i na zvířecích modelech, a ochranou
            získanou očkováním či předchozím proděláním onemocnění ( Risk assessment for SARS-CoV-2
            variant: Omicron VOC-21NOV-01 (B.1.1.529) UK Health Security Agency 12 January 2022).
          </p>
        </div>
      </Box>
      {/*
      <Box>
        <H3>B.1.1.7 (20I/501Y.V1; britská)</H3>

        <div className="text-columns-2">
          <p>
            Poprvé se objevila v září 2020 v Kentu v UK, kde jí kvůli tomu nazývají
            &quot;kentská&quot;, ne &quot;britská&quot;. Do konce roku 2020 už měla na svědomí téměř
            60 procent všech nákaz nemocí covid-19 v Londýně. Počátkem roku 2021 se tato o asi 50%
            infekčnější varianta rozšířila do celé Evropy včetně Česka. U nás jsme jí poprvé
            detekovali 16. ledna a v současnosti je tu dominantní. V této variantě se nahromadilo
            větší množství mutací než v jiných variantách. Nejvýznamnější je mutace{' '}
            <Tag minimal>N501Y</Tag> v jednom ze šesti klíčových kontaktních míst na spike proteinu,
            které se váže na receptor ACE 2 a odpovídá za lepší schopnost viru přichytit se na
            buňkách organismu.
          </p>

          <p>
            Z této varianty se v UK již vyvinula varianta <Tag minimal>B.1.1.7+E484K</Tag>, kde
            další mutace snižuje účinnost protilátkové odpovědi proti původní variantě.
          </p>

          <p>
            Seznam důležitých mutací spike proteinu: <Tag minimal>69del</Tag>,
            <Tag minimal>70del</Tag>, <Tag minimal>144del</Tag>,<Tag minimal>(E484K*)</Tag>,
            <Tag minimal>(S494P*)</Tag>, <Tag minimal>N501Y</Tag>, <Tag minimal>A570D</Tag>,
            <Tag minimal>D614G</Tag>, <Tag minimal>P681H</Tag>, <Tag minimal>T716I</Tag>,
            <Tag minimal>S982A</Tag>, <Tag minimal>D1118H (K1191N*)</Tag>
          </p>
        </div>

        <div className="text-align-center">
          <img src={b117} alt="Varianta B.1.1.7" />
          <p>
            Obrázek z{' '}
            <ExternalLink href="https://outbreak.info/situation-reports">
              https://outbreak.info/situation-reports
            </ExternalLink>
          </p>
        </div>
      </Box>

      <Box>
        <H3>B.1.351 (20H/501.V2; jihoafrická)</H3>

        <div className="text-columns-2">
          <p>
            Některé mutace má společné s variantou <Tag minimal>B.1.1.7</Tag>. a dnes se již
            rozšířila do celého světa, včetně České Republiky, kde je od února. Má zvýšenou
            infekčnost, navíc mutace <Tag minimal>K417N</Tag> a<Tag minimal>E484K</Tag> , u kterých
            je potvrzeno, že snižují účinek protilátek, a to včetné léčebných monoklonálních
            protilátek, jako například bamlanivimab. Infekce touto varientou zhoršuje průběh
            onemocnění u mladších ročníků.
          </p>

          <p>
            Seznam důležitých mutací spike proteinu: <Tag minimal>D80A</Tag>,{' '}
            <Tag minimal>D215G</Tag>, <Tag minimal>241del</Tag>, <Tag minimal>242del</Tag>,{' '}
            <Tag minimal>243del</Tag>, <Tag minimal>K417N</Tag>,<Tag minimal>E484K</Tag>,{' '}
            <Tag minimal>N501Y</Tag>, <Tag minimal>D614G</Tag>, <Tag minimal>A701V</Tag>
          </p>
        </div>

        <div className="text-align-center">
          <img src={b1351} alt="Varianta B.1.351" />
          <p>
            Obrázek z{' '}
            <ExternalLink href="https://outbreak.info/situation-reports">
              https://outbreak.info/situation-reports
            </ExternalLink>
          </p>
        </div>
      </Box>

      <Box>
        <H3>P.1 (B.1.1.28.; 20J/501Y.V3; brazilská)</H3>

        <div className="text-columns-2">
          <p>
            Poprvé ji detekovali na konci roku 2020 v Tokiu u čtyř turistů, kteří přiletěli z
            brazilského státu Amazonas. Ve městě Manaus v Brazílii se masivně rozšířila a je nyní
            zodpovědná za většinu tamních nákaz. Je infekčnější než původní varianta koronaviru ale
            hlavně uniká již získané imunitě, takže se lidé mnohem častěji nakazí podruhé. Navíc má
            horší průběh u mladších ročníků pod 65 let. Dvě mutace zodpovědné za imunitní únik má na
            stejném místě (<Tag minimal>K417T</Tag>, <Tag minimal>E484K</Tag>), jako varianta
            jihoafrická, ale obě varianty je získaly nezávisle na sobě.
          </p>

          <p>
            Seznam důležitých mutací spike proteinu: <Tag minimal>L18F</Tag>,{' '}
            <Tag minimal>T20N</Tag>, <Tag minimal>P26S</Tag>, <Tag minimal>D138Y</Tag>,{' '}
            <Tag minimal>R190S</Tag>, <Tag minimal>K417T</Tag>, <Tag minimal>E484K</Tag>,
            <Tag minimal>N501Y</Tag>, <Tag minimal>D614G</Tag>, <Tag minimal>H655Y</Tag>,{' '}
            <Tag minimal>T1027I</Tag>
          </p>
        </div>

        <div className="text-align-center">
          <img src={p1} alt="Varianta P.1" />
          <p>
            Obrázek z{' '}
            <ExternalLink href="https://outbreak.info/situation-reports">
              https://outbreak.info/situation-reports
            </ExternalLink>
          </p>
        </div>
      </Box>

      <Box>
        <H3>B.1.617.2 (21A; G/452R.V3; indická)</H3>

        <div className="text-columns-2">
          <p>
            Je zodpovědná za enormní nárůst pandemie v Indii v první půli roku 2021, i když objevena
            byla již na konci roku 2020. V UK se tato varianta šíří rychleji než do té doby
            dominantní varianta britská, která se doposud šířila největší rychlostí. Proto její
            chování vyvolává obavy. Obsahuje jiný set klíčových mutací než předcházející tři
            varianty: <Tag minimal>L452R</Tag>, <Tag minimal>T478K</Tag>, <Tag minimal>P681R</Tag>.
            U prvních dvou mutací se jedná o mutace ovlivňující imunitní odpověď. Spolu s ní se z
            Indie šíří další dvě varianty, <Tag minimal>B.1.617.1</Tag> a{' '}
            <Tag minimal>B.1.617.3</Tag>, které také obsahují mutaci <Tag minimal>L452R</Tag>.
          </p>

          <p>
            Seznam dmutací spike proteinu: <Tag minimal>T19R</Tag>, <Tag minimal>157del</Tag>,{' '}
            <Tag minimal>158del</Tag>, <Tag minimal>L452R</Tag>, <Tag minimal>T478K</Tag>,{' '}
            <Tag minimal>D614G</Tag>, <Tag minimal>P681R</Tag>, <Tag minimal>D950N</Tag>
          </p>
        </div>

        <div className="text-align-center">
          <img src={b16172} alt="Varianta B.1.617.2" />
          <p>
            Obrázek z{' '}
            <ExternalLink href="https://outbreak.info/situation-reports">
              https://outbreak.info/situation-reports
            </ExternalLink>
          </p>
        </div>
      </Box> */}
    </>
  );
}

import { gql, useQuery } from '@apollo/client';
import { Button, ControlGroup, FormGroup, MenuItem, Radio, RadioGroup } from '@blueprintjs/core';
import { DateRangeInput } from '@blueprintjs/datetime';
import { IItemRendererProps, MultiSelect } from '@blueprintjs/select';
import { GraphqlSchema } from '@covd/lib';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import MomentLocaleUtils from 'react-day-picker/moment';

interface GraphqlData {
  sampleLineages: GraphqlSchema.SampleLineage[];
}

export interface ControllerProps {
  state: ControllerState;
  dispatch: React.Dispatch<ControllerAction>;
}

interface FormProps extends ControllerProps {
  isSticky: boolean;
}

export interface ControllerState {
  selected: GraphqlSchema.SampleLineage[];
  key: number;
  chartType: ChartType;
  minRepresentation?: number;
  groupBy: 'week' | 'month';
  range: {
    from?: Date;
    to?: Date;
  };
}

type ChartType = 'absolute' | 'relative' | 'cumulative';

export type ControllerAction =
  | { action: 'toggleSelected'; item: GraphqlSchema.SampleLineage }
  | { action: 'removeSelectedAt'; index: number }
  | { action: 'clearSelected' }
  | { action: 'setChartType'; type: ChartType }
  | { action: 'setMinRepresentation'; value?: number }
  | { action: 'setRange'; from?: Date; to?: Date }
  | { action: 'setGroupBy'; value: ControllerState['groupBy'] };

export function controllerStateInitialValue(): ControllerState {
  return {
    selected: [],
    key: Date.now(),
    chartType: 'absolute',
    groupBy: 'week',
    range: {
      from: DateTime.now().minus({ month: 6 }).startOf('month').toJSDate(),
      to: new Date(),
    },
  };
}

export function controllerReducer(
  state: ControllerState,
  action: ControllerAction,
): ControllerState {
  switch (action.action) {
    case 'toggleSelected': {
      const index = _.indexOf(state.selected, action.item);

      if (index < 0) {
        state = { ...state, selected: [...state.selected, action.item] };
      } else {
        _.remove(state.selected, action.item);
      }

      break;
    }
    case 'removeSelectedAt': {
      state.selected.splice(action.index, 1);
      break;
    }
    case 'clearSelected': {
      state.selected = [];
      break;
    }
    case 'setChartType': {
      state.chartType = action.type;
      break;
    }
    case 'setMinRepresentation': {
      state.minRepresentation = action.value;
      break;
    }
    case 'setRange': {
      state.range = { from: action.from, to: action.to };
      break;
    }
    case 'setGroupBy': {
      state.groupBy = action.value;
      break;
    }
  }

  state.key = Date.now();

  return { ...state };
}

const Lineages = MultiSelect.ofType<GraphqlSchema.SampleLineage>();

export function Controller(props: ControllerProps): JSX.Element {
  const [isSticky, setIsSticky] = useState(false);
  const ref = React.createRef<HTMLDivElement>();

  // useEffect(() => {
  //   const cachedRef = ref.current;

  //   if (cachedRef) {
  //     const observer = new IntersectionObserver(
  //       ([entries]) => setIsSticky(entries.intersectionRatio < 1),
  //       { threshold: [1] },
  //     );

  //     observer.observe(cachedRef);

  //     return () => {
  //       observer.unobserve(cachedRef);
  //     };
  //   }
  // }, []);

  return (
    <>
      <div
        ref={ref}
        className={`box lineages-controller ${
          isSticky ? 'lineages-controller--sticky {Classes.DARK}' : ''
        } `}
      >
        <Form {...props} isSticky={isSticky} />
      </div>
    </>
  );
}

function Form(props: FormProps): JSX.Element | null {
  const { loading, error, data } = useQuery<GraphqlData>(gql`
    query {
      sampleLineages {
        name
        count
      }
    }
  `);

  if (loading || error || !data?.sampleLineages?.length) {
    return null;
  }

  const itemRenderer = (item: GraphqlSchema.SampleLineage, options: IItemRendererProps) => {
    if (!options.modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={options.modifiers.active}
        disabled={options.modifiers.disabled}
        label={`${item.count}x`}
        onClick={options.handleClick}
        text={item.name}
        icon={props.state.selected.includes(item) ? 'tick' : 'blank'}
        shouldDismissPopover={false}
        key={item.name}
      />
    );
  };

  const clearButton = props.state.selected.length ? (
    <Button icon="cross" onClick={() => props.dispatch({ action: 'clearSelected' })} minimal />
  ) : undefined;

  return (
    <>
      <ControlGroup className="padding-gaps-20" style={{ position: 'sticky', top: 0 }} fill>
        <FormGroup label="Datum odběru" labelInfo="(prosím vložte obě hodnoty)">
          <DateRangeInput
            formatDate={(date) => date.toLocaleDateString('cs')}
            locale="cs"
            localeUtils={MomentLocaleUtils}
            onChange={(range) => {
              props.dispatch({
                action: 'setRange',
                from: range[0] || undefined,
                to: range[1] || undefined,
              });
            }}
            parseDate={(str) => new Date(str)}
            defaultValue={[props.state.range.from || null, props.state.range.to || null]}
            shortcuts={false}
            // closeOnSelection
            // allowSingleDayRange
            contiguousCalendarMonths={false}
          />
        </FormGroup>

        <FormGroup label="Linie" labelInfo="(vyberte jednu nebo více)">
          <Lineages
            placeholder="Klikni sem"
            itemRenderer={itemRenderer}
            items={data.sampleLineages}
            selectedItems={props.state.selected}
            onItemSelect={(item) => props.dispatch({ action: 'toggleSelected', item })}
            tagRenderer={(item) => item.name}
            popoverProps={{ minimal: true }}
            itemPredicate={itemPredicate}
            itemsEqual={(item1, item2) => item1.name === item2.name}
            tagInputProps={{
              onRemove: (_, index) => props.dispatch({ action: 'removeSelectedAt', index }),
              rightElement: clearButton,
              tagProps: { minimal: true },
            }}
          />
        </FormGroup>
      </ControlGroup>

      <ControlGroup className="padding-gaps-20" fill>
        <RadioGroup
          label="Typ grafu"
          onChange={(event) => {
            props.dispatch({
              action: 'setChartType',
              type: event.currentTarget.value as ChartType,
            });
          }}
          selectedValue={props.state.chartType}
          // inline={props.isSticky}
          inline
        >
          <Radio label="Absolutní" value="absolute" />
          <Radio label="Relativní" value="relative" />
          <Radio label="Kumulativní" value="cumulative" />
        </RadioGroup>

        <RadioGroup
          label="Zastoupení linii"
          onChange={(event) => {
            const value = event.currentTarget.value;

            props.dispatch({
              action: 'setMinRepresentation',
              value: value ? parseInt(value) : undefined,
            });
          }}
          selectedValue={props.state.minRepresentation?.toString() || ''}
          disabled={props.state.selected.length > 0}
          // inline={props.isSticky}
          inline
        >
          <Radio label="Vše" value="" />
          <Radio label="Minimálně 1%" value="1" />
          <Radio label="Minimálně 5%" value="5" />
          <Radio label="Minimálně 10%" value="10" />
        </RadioGroup>

        <RadioGroup
          label="Seskupení v čase"
          onChange={(event) => {
            props.dispatch({
              action: 'setGroupBy',
              value: event.currentTarget.value === 'month' ? 'month' : 'week',
            });
          }}
          selectedValue={props.state.groupBy}
          inline
        >
          <Radio label="Týdně" value="week" />
          <Radio label="Měsíčně" value="month" />
        </RadioGroup>
      </ControlGroup>
    </>
  );
}

function itemPredicate(
  query: string,
  item: GraphqlSchema.SampleLineage,
  _index?: number,
  exactMatch?: boolean,
) {
  if (exactMatch) {
    return item.name.toLowerCase() === query.toLowerCase();
  } else {
    return item.name.toLowerCase().includes(query.toLowerCase());
  }
}

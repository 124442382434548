import React from 'react';
import { Box } from '../../components/box';
import { Level } from '../../components/level';
import { GraphqlSchema } from '@covd/lib';

interface StatsProps {
  sampleStats?: GraphqlSchema.SampleStats;
}

export function Stats(props: StatsProps) {
  if (!props.sampleStats) {
    return null;
  }

  const { count, lineagesCount } = props.sampleStats;

  const stats = [
    { label: 'Počet vzorků', value: count },
    { label: 'Počet linií', value: lineagesCount },
  ];

  return (
    <Box>
      <Level items={stats} large />
    </Box>
  );
}

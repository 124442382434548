import { gql, useQuery } from '@apollo/client';
import { H3 } from '@blueprintjs/core';
import { GraphqlSchema } from '@covd/lib';
import * as _ from 'lodash';
import React from 'react';
import { Box } from '../../components/box';
import { Error } from '../../components/error';
import { Loading } from '../../components/loading';
import { NoDataToDisplay } from '../../components/noDataToDisplay';
import { Treemap } from '../../components/treemap/treemap';
import { getLineageColor } from '../../utils/lineageColor';
import { ControllerProps } from './controller';

export interface GraphqlData {
  lineages: GraphqlSchema.Lineage[];
}

export const graphqlQuery = gql`
  query {
    lineages {
      lineage
      count
    }
  }
`;

export function CountsTotal(props: ControllerProps): JSX.Element {
  const { loading, error, data } = useQuery<GraphqlData>(graphqlQuery);

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;

  if (!data?.lineages?.length) {
    return <NoDataToDisplay />;
  }

  let items = _.reverse(_.sortBy(data.lineages, 'count'));

  if (props.state.selected.length) {
    items = items.filter((item) =>
      props.state.selected.find((selectedItem) => selectedItem.name === item.lineage),
    );
  } else if (props.state.minRepresentation) {
    const totalCount = _.sumBy(items, 'count');
    const minCount = totalCount * (props.state.minRepresentation / 100);

    items = items.filter((item) => item.count >= minCount);
  }

  return (
    <Box>
      <H3>Linie SARS-CoV-2 detekované v Česku (celé období)</H3>

      <Treemap
        items={items.map((item) => {
          return {
            value: item.count,
            color: getLineageColor(item.lineage),
            label: item.lineage,
          };
        })}
      />

      {/* <RenderChart
        configKey={props.state.key}
        height={100}
        options={{
          type: 'bar',
          data: {
            datasets: [
              { data: items.map((item) => item.count), backgroundColor: colorGenerator('first') },
            ],
            labels: items.map((item) => item.lineage),
          },
          options: {
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                grid: { display: false },
              },
            },
          },
        }}
      /> */}
    </Box>
  );
}

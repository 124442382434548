import { ApolloClient, InMemoryCache } from '@apollo/client';

export const config = {
  serverUrl:
    process.env.NODE_ENV === 'production'
      ? window.location.protocol + '//' + window.location.host
      : 'http://localhost:5000',
  gaTrackingCode: 'UA-189096649-1',
};

export const graphqlClient = new ApolloClient({
  uri: `${config.serverUrl}/graphql`,
  cache: new InMemoryCache(),
});

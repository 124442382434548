import { gql, useQuery } from '@apollo/client';
import { GraphqlSchema } from '@covd/lib';
import React from 'react';
import { Error } from '../../components/error';
import { Loading } from '../../components/loading';
import { NoDataToDisplay } from '../../components/noDataToDisplay';
import { Title } from '../../components/title';
import { Institutes } from './institutes';
import { Laboratories } from './laboratories';
import { ReportFiles } from './reportFiles';
import { Stats } from './stats';

export interface GraphqlData {
  valuesStore: Pick<GraphqlSchema.ValuesStore, 'sampleStats'>;
  laboratories: GraphqlSchema.Laboratory[];
  report: {
    reportFiles: GraphqlSchema.ReportFile[];
  };
}

export const graphqlQuery = gql`
  query {
    valuesStore {
      sampleStats {
        count
        minCollectionDate
        maxCollectionDate
        lineagesCount
      }
    }
    laboratories {
      id
      name
      website
      numberOfSamples
    }
    report {
      reportFiles {
        size
        name
      }
    }
  }
`;

export function HomePage(): JSX.Element {
  const { loading, error, data } = useQuery<GraphqlData>(graphqlQuery);

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;

  if (!data) {
    return <NoDataToDisplay />;
  }

  return (
    <>
      <Title>COG-CZ - Česká Republika</Title>

      <Stats sampleStats={data.valuesStore.sampleStats} />
      <ReportFiles reportFiles={data.report.reportFiles} />
      <Laboratories laboratories={data.laboratories} />
      <Institutes />
    </>
  );
}

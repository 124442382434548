import { Icon, IconName } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { externalLinkEvent } from '../tracking';
import './links.css';

interface ExternalLinkProps {
  href: string;
  icon?: IconName;
  className?: string;
}

interface AccessionNumbersLinksProps {
  numbers?: string[];
}

interface LinkWithIconProps {
  to: string;
  icon?: IconName;
}

export function ExternalLink(props: PropsWithChildren<ExternalLinkProps>): JSX.Element {
  const icon = props.icon || IconNames.DOCUMENT_OPEN;

  return (
    <a
      href={props.href}
      target="_blank"
      rel="noreferrer"
      className={`link-with-icon no-wrap ${props.className}`}
      onClick={(event) => {
        externalLinkEvent(event.currentTarget.href);
      }}
    >
      <Icon icon={icon} />
      {props.children}
    </a>
  );
}

export function AccessionNumbersLinks(props: AccessionNumbersLinksProps): JSX.Element {
  if (!props.numbers) {
    return <></>;
  }

  return (
    <>
      {props.numbers.map((number) => (
        <ExternalLink key={number} href={`https://dfam.org/family/${number}`}>
          {number}
        </ExternalLink>
      ))}
    </>
  );
}

export function LinkWithIcon(props: PropsWithChildren<LinkWithIconProps>): JSX.Element {
  return (
    <Link to={props.to}>
      {props.icon && <Icon icon={props.icon} />} {props.children}
    </Link>
  );
}

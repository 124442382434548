import { Icon } from '@blueprintjs/core';
import { uniq } from 'lodash';
import React from 'react';
import './pagination.css';

interface PaginationProps {
  count: number;
  page: number;
  limit: number;

  callback: (newPage: number) => void;
}

export function Pagination(props: PaginationProps) {
  const { count, page: currentPage, limit, callback } = props;

  const lastPage = Math.floor(count / limit);

  const pages = uniq(
    [
      1,
      currentPage - 3,
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      currentPage + 2,
      currentPage + 3,
      lastPage - 2,
      lastPage - 1,
      lastPage,
    ].filter((page) => page >= 1 && page <= lastPage),
  );

  let prevPage = 0;
  const pageItems: JSX.Element[] = [];

  pages.forEach((page) => {
    if (page !== prevPage + 1) {
      pageItems.push(<span>...</span>);
    }

    if (currentPage === page) {
      pageItems.push(<span>{page}</span>);
    } else {
      pageItems.push(<a onClick={() => callback(page)}>{page}</a>);
    }

    prevPage = page;
  });

  return (
    <div className="pagination">
      {currentPage > 1 && (
        <a onClick={() => callback(currentPage - 1)}>
          <Icon icon="double-chevron-left" />
        </a>
      )}

      {pageItems}

      {currentPage < lastPage && (
        <a onClick={() => callback(currentPage + 1)}>
          <Icon icon="double-chevron-right" />
        </a>
      )}
    </div>
  );
}

export function hasPages(params: { count: number; limit: number }) {
  return params.count > params.limit;
}

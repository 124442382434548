import { ControlGroup, FormGroup, H3, Radio, RadioGroup } from '@blueprintjs/core';
import { DateRangeInput } from '@blueprintjs/datetime';
import { DateTime, Duration, Interval } from 'luxon';
import React, { useState } from 'react';
import MomentLocaleUtils from 'react-day-picker/moment';
import { Box } from '../../components/box';
import { Map } from './map';

interface CollectionDate {
  collectionDateGte: DateTime;
  collectionDateLte: DateTime;
}

type GroupBy = 'week' | 'month';
type Scale = 'linear' | 'log';

export function SamplesMapPage(): JSX.Element {
  const [collectionDate, setCollectionDate] = useState<CollectionDate>({
    collectionDateGte: DateTime.now().minus({ week: 2 }).startOf('week'),
    collectionDateLte: DateTime.now(),
  });
  const [groupBy, setGroupBy] = useState<GroupBy>('week');
  const [scale, setScale] = useState<Scale>('log');

  const intervals = Interval.fromDateTimes(
    collectionDate.collectionDateGte.setLocale('cs').startOf(groupBy),
    collectionDate.collectionDateLte.setLocale('cs').endOf(groupBy),
  ).splitBy(Duration.fromObject({ [groupBy]: 1 }));

  const maps: JSX.Element[] = [];

  for (const interval of intervals) {
    maps.push(
      <Box style={{ position: 'relative' }}>
        <H3>
          Období: {interval.start.toLocaleString()} -{' '}
          {interval.start.endOf(groupBy).toLocaleString()}
          <Map
            state={{
              collectionDateGte: interval.start.toJSDate(),
              collectionDateLte: interval.end.toJSDate(),
            }}
            scale={scale}
            hideTitle
          />
        </H3>
      </Box>,
    );
  }

  return (
    <>
      <Box>
        <ControlGroup className="padding-gaps-20">
          <CollectionDateFilter
            collectionDate={collectionDate}
            setCollectionDate={(values) => setCollectionDate(values)}
          />
          <GroupByFilter groupBy={groupBy} setGroupBy={(value) => setGroupBy(value)} />
          <ScaleFilter scale={scale} setScale={(value) => setScale(value)} />
        </ControlGroup>
      </Box>

      {maps}
    </>
  );
}

function CollectionDateFilter({
  collectionDate,
  setCollectionDate,
}: {
  collectionDate: CollectionDate;
  setCollectionDate: (values: CollectionDate) => void;
}): JSX.Element {
  return (
    <FormGroup label="Datum odběru" labelInfo="(vložte jedno datum nebo oboje)">
      <DateRangeInput
        formatDate={(date) => date.toLocaleDateString('cs')}
        locale="cs"
        localeUtils={MomentLocaleUtils}
        onChange={(range) =>
          range[0] &&
          range[1] &&
          setCollectionDate({
            collectionDateGte: DateTime.fromJSDate(range[0]),
            collectionDateLte: DateTime.fromJSDate(range[1]),
          })
        }
        parseDate={(str) => new Date(str)}
        defaultValue={[
          collectionDate.collectionDateGte.toJSDate(),
          collectionDate.collectionDateLte.toJSDate(),
        ]}
        closeOnSelection
        shortcuts={false}
        allowSingleDayRange
      />
    </FormGroup>
  );
}

function GroupByFilter({
  groupBy,
  setGroupBy,
}: {
  groupBy: GroupBy;
  setGroupBy: (value: GroupBy) => void;
}) {
  return (
    <RadioGroup
      label="Seskupení v čase"
      onChange={(event) => setGroupBy(event.currentTarget.value as GroupBy)}
      selectedValue={groupBy}
    >
      <Radio label="Týdně" value="week" />
      <Radio label="Měsíčně" value="month" />
    </RadioGroup>
  );
}

function ScaleFilter({ scale, setScale }: { scale: Scale; setScale: (value: Scale) => void }) {
  return (
    <RadioGroup
      label="Zobrazení"
      onChange={(event) => setScale(event.currentTarget.value as Scale)}
      selectedValue={scale}
    >
      <Radio label="Logaritmické" value="log" />
      <Radio label="Lineární" value="linear" />
    </RadioGroup>
  );
}

// function Map({collectionDate}:{collectionDate: CollectionDate;}):JSX.Element{
//     const { loading, error, data } = useQuery<GraphqlData, GraphqlSchema.SamplesFilter>(
//     graphqlQuery,
//     {
//       variables: {

//         collectionDateLte: collectionDate.collectionDateLte.toJSDate(),
//         collectionDateGte: collectionDate.collectionDateGte.toJSDate(),
//       },
//     },
//   );

//   if (loading) return <Loading />;
//   if (error) return <Error message={error.message} />;
// }

import React from 'react';
import { Layer, Rect, Stage, Text } from 'react-konva';
import { getCSS } from '../../utils/css';
import { getTreemap, TreemapDataItem } from './calc';
import { textWidth } from './utils';
import invert from 'invert-color';

interface TreemapProps {
  items: TreemapDataItem[];
}

export function Treemap(props: TreemapProps): JSX.Element {
  const width = parseInt(getCSS('--content-width')) - parseInt(getCSS('--box-padding')) * 2;
  const height = 200;
  const labelFontSize = 13;

  const tree = getTreemap({
    data: props.items,
    height,
    width,
  });

  return (
    <div>
      <Stage width={width} height={height}>
        <Layer>
          {tree.map((item, index) => {
            const result = [
              <Rect
                key={index}
                x={item.x}
                y={item.y}
                width={item.width}
                height={item.height}
                fill={item.data.color}
                shadowBlur={2}
              />,
            ];

            if (item.data.label && item.data.color) {
              const labelWidth = textWidth(item.data.label, labelFontSize);

              if (item.width >= labelWidth) {
                result.push(
                  <Text
                    text={item.data.label}
                    x={item.x + item.width / 2 - labelWidth / 2}
                    y={item.y + item.height / 2 - labelFontSize / 2}
                    fontSize={labelFontSize}
                    fill={invert(item.data.color, true)}
                  />,
                );
              }
            }

            return result;
          })}
        </Layer>
      </Stage>
    </div>
  );
}
